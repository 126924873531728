<script setup lang="ts">
import {ArticleResponse} from "../../../responses/ArticleResponse";
import {computed} from "vue";

interface Props {
    article: ArticleResponse
}

const props = withDefaults(defineProps<Props>(), {});

const tagString = computed(() => {
    return props.article.tags?.map(item => item.label).join(", ") || '';
})

</script>

<template>
    <div class="w-full flex flex-col relative">
        <div class="flex flex-row absolute top-2 right-2 z-20">
            <span class="text-sm py-2 px-3 bg-white font-medium rounded-xl">{{ article.published_at_iso }}</span>
        </div>
        <a :href="article.full_slug">
            <img :src="article.image_path + `?fm=webp&q=90&w=450`" :alt="article.title" class="object-cover rounded-t-2xl w-full">
        </a>
        <div class="w-full py-4 flex flex-col h-full">
            <h2 class="border-b border-gray-200 pb-2 text-left items-center flex lg:min-h-[4.25rem]">
                <a :href="article.full_slug">{{ article.title }}</a>
            </h2>
            <div class="mt-2 text-base leading-8 text-gray-900 text-left flex-1" v-if="article.content_short" v-html="article.content_short"></div>
            <div class="w-full border-t border-gray-200 mt-2 pt-4 text-gray-700" v-if="article.tags.length">
                <ul class="w-full text-base list-none pb-4">
                    <li class="flex">
                        <span class="mr-4">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                               stroke="#cc006c" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                               class="lucide lucide-tags"><path d="m15 5 6.3 6.3a2.4 2.4 0 0 1 0 3.4L17 19"/><path
                              d="M9.586 5.586A2 2 0 0 0 8.172 5H3a1 1 0 0 0-1 1v5.172a2 2 0 0 0 .586 1.414L8.29 18.29a2.426 2.426 0 0 0 3.42 0l3.58-3.58a2.426 2.426 0 0 0 0-3.42z"/><circle
                              cx="6.5" cy="9.5" r=".5" fill="currentColor"/></svg>
                        </span>
                        <span v-text="tagString"></span>
                    </li>
                </ul>
            </div>
            <div class="flex flex-col gap-5 lg:items-start mt-4">
                <a
                    :href="article.full_slug"
                    class="flex justify-center px-8 py-3 text-white font-bold bg-primary hover:bg-primary-600 rounded-full focus:bg-primary-500 focus:outline-none"
                >
                    Lees meer
                </a>
            </div>
        </div>
    </div>
</template>
