<script setup lang="ts">
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'
import {ArticleResponse} from "../../../responses/ArticleResponse";
import ArticleItem from "./ArticleItem.vue";

interface Props {
    articles: Array<ArticleResponse>
}
const props = withDefaults(defineProps<Props>(), {

});

const config = {
    itemsToShow: 1,
    snapAlign: 'center',
    wrapAround: true,
    breakpoints: {
        400: {
            itemsToShow: 1,
            snapAlign: 'start',
        },
        800: {
            itemsToShow: 2,
            snapAlign: 'start',
        },
        1200: {
            itemsToShow: 3,
            snapAlign: 'start',
        },
        1600: {
            itemsToShow: 4,
            snapAlign: 'start',
        }
    }
}
</script>

<template>
    <Carousel v-bind="config">
        <Slide v-for="article in articles" :key="article.id">
            <ArticleItem :article="article"></ArticleItem>
        </Slide>
    </Carousel>
</template>
