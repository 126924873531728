export function trackAddToCartGoogle(product, quantity, add_or_remove_event) {
    if (window.seo_enabled) {
        window.dataLayer.push({ecommerce: null});
        window.dataLayer.push({
            event: add_or_remove_event,
            ecommerce: {
                items: [
                    {
                        item_id: product.code,
                        item_name: product.name,
                        affiliation: "LeerpuntKOEL",
                        index: 0,
                        price: (product.price_incl / 100).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}),
                        quantity: quantity
                    }
                ]
            }
        });
    }
}
