import {defineStore} from 'pinia';
import {ref, Ref} from "vue";

interface ReplaceList {
    [key: string]: string;
}
export const useLabelStore = defineStore('label', () => {
    const translations: Ref<Array<String>> = ref([]);

    const setFromJsonString = (json) => {
        if (!json) {
            console.error('Unable to set LabelStore from empty json')
            return;
        }

        translations.value = JSON.parse(json.replace(/\n/g, "\\n"));
    }
    const get = (key, replace: ReplaceList = {}) => {
       let label = translations.value[key];
        for(const [key, value] of Object.entries(replace)) {
            label = label.replaceAll(`{${key}}`, value);
        }

        return label || `No Translation for: ${key}`;
    }

    return {
        translations,
        setFromJsonString,
        get,

    }
});
