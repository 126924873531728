<script setup lang="ts">
import CartList from './CartList.vue';
import {label} from '../../mixins/translations.js';
import {useCartStore} from "../../stores/cart";

interface Props{
    loginUrl: String
    categoryUrl: String
}
const props = defineProps<Props>();
const cart = useCartStore();
const goToNextStep = () => {
    window.location = props.loginUrl;
    return false;
}
</script>
<template>
        <div class="flex flex-col w-full">
            <h1>{{ label('site.cart.overview.title') }}</h1>

            <CartList></CartList>

            <div class="flex flex-col lg:flex-row w-full pb-8">
                <div class="flex flex-row lg:flex-col w-full w-full items-end justify-end lg:justify-normal lg:mt-0" v-if="cart.getItemCount() > 0">
                    <div class="flex flex-row items-end mt-2">
                        <p>{{ label('site.cart.summary.total') }}</p>
                        <span class="ml-3 text-xl font-bold tracking-tight text-gray-900 lg:text-2xl mt-2">{{ $root.formatPrice(cart.getTotal(), cart.cart_currency) }}</span>
                    </div>
                </div>
            </div>

            <div class="w-full flex flex-col lg:flex-row gap-4 justify-between">
                <a v-show="props.categoryUrl !== ''" :href="props.categoryUrl" class="hidden lg:block w-full lg:w-auto flex justify-center lg:px-16 py-4 font-bold rounded-full focus:outline-none border border-secondary-light-border text-secondary-950 bg-secondary-light hover:bg-secondary-200" type="button">
                    {{ label('site.cart.add_more_products') }}
                </a>
                <a href="javascript:" v-show="cart.getItemCount() > 0" @click="goToNextStep()" class="w-full text-center lg:w-auto flex justify-center lg:px-16 py-4 text-white font-bold rounded-full focus:outline-none text-white bg-secondary-950 hover:bg-secondary-900" type="button">
                    {{ label('site.cart.next_step') }}
                </a>
            </div>
        </div>
</template>
