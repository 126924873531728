import type {AxiosInstance, AxiosResponse} from "axios"
import axios from "axios"

const apiInstance: AxiosInstance = axios.create({
    baseURL: '/api',
    timeout: 3000,
})

apiInstance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

//TODO: Wildcard
apiInstance.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

apiInstance.defaults.headers.common["x-language"] = window.language;

let api_token = document.head.querySelector('meta[name="api-token"]');
if (api_token) {
    apiInstance.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
    apiInstance.defaults.headers.common["Authorization"] = "Bearer " + api_token.content;
}

export function api(): AxiosInstance {
    return apiInstance
}
