import {defineStore} from 'pinia';
import {useFilterStore} from "./filter.ts";
import mitt, {Emitter} from "mitt";
import {reactive, ref, Ref, UnwrapNestedRefs} from "vue";
import {AxiosRequestConfig, AxiosResponse, isCancel} from "axios";
import {api} from "../scripts/api";
import {ProductResponse} from "../../responses/ProductResponse";

type Events = {
    updatedFilter: void
    initPriceFilter: void
}
export const useProductStore = defineStore('product', () => {
    const events: Emitter<Events> = mitt<Events>()
    const products: Ref<Array<ProductResponse>> = ref([]);
    const defaultValues = {
        'page': 1,
        'sort': 'variants_start_date|asc'
    };
    const sort: Ref<String> = ref(defaultValues.sort);
    const page: Ref<Number> = ref(defaultValues.page);
    const perPage: Ref<Number> = ref(15); //where to put these default values?
    const productCount: Ref<Number> = ref(0);
    const categorySlug: Ref<String> = ref('');
    const priceMax: Ref<Number> = ref(100);
    const priceMin: Ref<Number> = ref(0);

    const getProductRequest: UnwrapNestedRefs<{
        timeout: number | null
        abort: AbortController | null
    }> = reactive({
        timeout: null,
        abort: null,
    })

    const hasMoreResults = () => {
        return productCount.value >= (page.value * perPage.value);
    }

    const getProducts = async <T>(): Promise<T> => {
        return new Promise<T>(async (resolve, reject): Promise<void> => {
            if (getProductRequest.abort !== null) {
                getProductRequest.abort.abort()
            }

            getProductRequest.abort = new AbortController()

            try {
                const sortParams = {};
                // if(defaultValues['page'] !== page.value) sortParams['page'] = page.value;
                if(defaultValues['sort'] !== sort.value) sortParams['sort'] = sort.value;

                const filterStore = useFilterStore();
                filterStore.toUrlString(page.value, sortParams, false, true);
                const filterString = filterStore.toRawUrlString(page.value, sortParams);
                const category = categorySlug.value ? '/' + categorySlug.value : '';

                const response: void | AxiosResponse<T> = await api().post(
                    `/site/products${category}/${filterString}`,
                    {
                        lang: window.language,
                        perPage: perPage.value,
                        defaultFilters: filterStore.defaultFilters
                    },
                    {signal: getProductRequest.abort.signal} as AxiosRequestConfig,
                )

                setProducts(response.data);
                setCount(response.data.meta.total);
                filterStore.tagCategories = response.data.filters;
                resolve(response.data);
            } catch (e) {
                reject(e)
            }
        });
    }
    const setProducts = (newProducts) => {
        products.value = newProducts;
    }

    const setSort = (newSort) => {
        sort.value = newSort;
    }

    const setNextPage = () => {
        if(hasMoreResults()) {
            page.value = page.value + 1;
        }
    }

    const setPerPage = (newPerPage) => {
        perPage.value = newPerPage;
    }

    const setCount = (newCount) => {
        productCount.value = newCount;
    }

    const setCategorySlug = (slug) => {
        categorySlug.value = slug;
    }

    const setPage = (newPage) => {
        page.value = newPage;
    }

    return {
        events,
        products,
        sort,
        page,
        perPage,
        productCount,
        categorySlug,
        priceMin,
        priceMax,
        hasMoreResults,
        getProducts,
        setProducts,
        setSort,
        setNextPage,
        setPerPage,
        setCount,
        setCategorySlug,
        setPage,
    }
});
