<script setup lang="ts">
import {computed, onMounted, onUnmounted, ref} from "vue";
import {CategoryResponse} from "../../../responses/CategoryResponse";
import {TagResponse} from "../../../responses/TagResponse";
import {join} from 'lodash';
import {label} from "../../mixins/translations";

interface Props{
    professionOptions: Array<TagResponse>
    learningOptions: Array<TagResponse>
    category: CategoryResponse
}
const props = defineProps<Props>();
const professionChoice = ref(null);
const learningChoice = ref(null);
const url = computed(() => {
    let catUrl = props.category.slug + '/';
    let choices = [];
    if(professionChoice.value !== null) choices.push('tag[]=' + professionChoice.value);
    if(learningChoice.value !== null) choices.push('tag[]=' + learningChoice.value);
    if(choices.length) return catUrl + '?' + join(choices, '&');
    else return catUrl;
})
</script>

<template>
    <div class="w-full px-10 py-10">
        <div class="w-full">
            <h3 class="text-lg font-bold tracking-tight text-gray-900">{{ label('site.home.select_occupation') }}</h3>
            <div class="grid relative mt-2">
                <select style="background:none" id="beroepsgroep" v-model="professionChoice" class="!bg-white row-start-1 col-start-1 appearance-none border border-gray-300 text-gray-500 text-base rounded focus:ring-gray-400 outline-none block w-full px-12 py-3 placeholder:text-gray-300 rounded-r-full">
                    <option disabled selected :value="null">Maak een keuze</option>
                    <option v-for="tag in professionOptions" :value="tag.slug">{{ tag.label }}</option>
                </select>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="absolute w-6 h-6 left-3 top-3 pointer-events-none row-start-1 col-start-1 lucide lucide-graduation-cap"><path d="M21.42 10.922a1 1 0 0 0-.019-1.838L12.83 5.18a2 2 0 0 0-1.66 0L2.6 9.08a1 1 0 0 0 0 1.832l8.57 3.908a2 2 0 0 0 1.66 0z"/><path d="M22 10v6"/><path d="M6 12.5V16a6 3 0 0 0 12 0v-3.5"/></svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="absolute w-4 h-4 right-4 top-4 pointer-events-none row-start-1 col-start-1 lucide lucide-chevron-down"><path d="m6 9 6 6 6-6"></path></svg>
            </div>
        </div>
        <div class="w-full mt-6">
            <h3 class="text-lg font-bold tracking-tight text-gray-900">{{ label('site.home.learning_option') }}</h3>
            <div class="grid relative mt-2">
                <select style="background:none" id="uitvoeringsvorm" v-model="learningChoice" class="!bg-white row-start-1 col-start-1 appearance-none border border-gray-300 text-gray-500 text-base rounded focus:ring-gray-400 outline-none block w-full px-12 py-3 placeholder:text-gray-300 rounded-r-full">
                    <option disabled selected :value="null">Maak een keuze</option>
                    <option v-for="tag in learningOptions" :value="tag.slug">{{ tag.label }}</option>
                </select>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="absolute w-6 h-6 left-3 top-3 pointer-events-none row-start-1 col-start-1 lucide lucide-university"><circle cx="12" cy="10" r="1"/><path d="M22 20V8h-4l-6-4-6 4H2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2"/><path d="M6 17v.01"/><path d="M6 13v.01"/><path d="M18 17v.01"/><path d="M18 13v.01"/><path d="M14 22v-5a2 2 0 0 0-2-2a2 2 0 0 0-2 2v5"/></svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="absolute w-4 h-4 right-4 top-4 pointer-events-none row-start-1 col-start-1 lucide lucide-chevron-down"><path d="m6 9 6 6 6-6"></path></svg>
            </div>
        </div>
        <div class="flex flex-col mt-6">
            <a :href="url" class="px-8 py-4 w-full text-white text-center font-bold bg-primary-500 hover:bg-primary-600 hover:text-white rounded-full focus:outline-none">Bekijk ons aanbod</a>
        </div>
    </div>
</template>

