<script setup lang="ts">
import {ref} from "vue";
import {CheckoutStep, useCheckoutStore} from "../../../stores/checkout";
import Error from "../../Error.vue";
import {Loader2Icon} from "lucide-vue-next";
import {label} from "../../../mixins/translations";
import {useCartStore} from "../../../stores/cart";

interface Props{

}
const checkoutStore = useCheckoutStore();
const cartStore = useCartStore();
const props = defineProps<Props>();
const validationErrors = ref({});

const submitForm = () => {
    validationErrors.value = {};
    return checkoutStore.updateInvoiceInformation().then(response => {
        if(response.data.services_only){
            window.location = response.data.route;
        }
        else {
            checkoutStore.setStep(CheckoutStep.Participants);
        }
    }).catch(error => {
        validationErrors.value = error.errors;

        window.scroll({
            top: document.getElementById(Object.keys(error.errors)[0] + '_label').offsetTop, //scroll to first error
            left: 0,
            behavior: 'smooth'
        });
    });
}
const updateInvoice = (newAddressValue) => {
    checkoutStore.invoiceInformationForm = newAddressValue;
}
</script>

<template>
    <div class="container max-w-[800px] px-6 py-4 mx-auto gap-10 lg:gap-20 lg:flex-row">
        <div class="w-full bg-gray-50 py-8 px-8 lg:px-16 lg:py-16 rounded-xl">
            <h2 class="mb-2 border-b border-secondary-100 pb-4 flex justify-between align-center justify-center">{{ label('site.cart.invoice_information') }}</h2>

            <address-input
                :company-required="checkoutStore.deliveryInformationForm.payment === '1'"
                :show-company="checkoutStore.deliveryInformationForm.payment === '1'"
                :countries="checkoutStore.countryOptions"
                :hide-preview="true"
                default-country="NL"
                :show-inputs="true"
                :model-value="checkoutStore.invoiceInformationForm"
                @update:model-value="updateInvoice($event)"
                :errors="validationErrors"
                :cart="true"
            ></address-input>

            <div class="flex flex-col lg:flex-row gap-5 w-full mt-3">
                <div class="w-full lg:w-6/12">
                    <label for="email" class="block mb-2 text-sm font-medium text-gray-900" id="email_label">{{ label('site.cart.invoice_information.email') }} <span class="text-red-500">*</span></label>
                    <input type="text" v-model="checkoutStore.invoiceInformationForm.email" id="email" class="bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-black block w-full p-2.5" placeholder="" required />
                    <Error v-if="validationErrors.hasOwnProperty('email')" :error="validationErrors.email[0]"></Error>
                </div>
                <div class="w-full lg:w-6/12">
                    <label for="phone" class="block mb-2 text-sm font-medium text-gray-900" id="phone_label">{{ label('site.account.phone') }} <span class="text-red-500">*</span></label>
                    <input type="text" v-model="checkoutStore.invoiceInformationForm.phone" id="phone" class="bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-black block w-full p-2.5" placeholder="" required />
                    <Error v-if="validationErrors.hasOwnProperty('phone')" :error="validationErrors.phone[0]"></Error>
                </div>
            </div>
        </div>
        <div class="w-full flex flex-col lg:flex-row gap-4 justify-between mt-4 lg:mt-10">
            <button @click.prevent="checkoutStore.setStep(CheckoutStep.Delivery)" type="button" class="hidden lg:block w-full lg:w-auto flex justify-center px-16 py-4 font-bold text-gray-800 border border-gray-200 bg-gray-100 hover:border-gray-300 hover:bg-gray-200 rounded-full focus:bg-gray-100 focus:outline-none">
                {{ label('site.cart.step_back') }}
            </button>
            <button :class="{'cursor-wait bg-opacity-50 hover:none': checkoutStore.loading, 'hover:bg-primary-600': !checkoutStore.loading}" @click.prevent="submitForm" :disabled="checkoutStore.loading" type="button" class="w-full lg:w-auto flex justify-center px-16 py-4 text-white font-bold bg-primary rounded-full focus:bg-primary-600 focus:outline-none">
                <div class="justify-center items-center text-white/90" v-show="checkoutStore.loading">
                    <Loader2Icon stroke-width="3" class="w-5 h-5 animate-spin mr-2 mt-0.5"/>
                </div>

                <span v-if="!cartStore.hasOnlyServices()">{{ label('site.cart.invoice_information.go_to_participants') }}</span>
                <span v-else>{{ label('site.cart.participant_information.next_step') }}</span>
            </button>
        </div>
    </div>
</template>
