import {defineStore} from "pinia";
import {reactive, ref, Ref, UnwrapNestedRefs} from "vue";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {CartDeliveryInformationResponse} from "../../responses/Site/CartDeliveryInformationResponse";
import {api} from "../scripts/api";
import _ from "lodash";
import {useCartStore} from "./cart";
import {CartInvoiceInformationResponse} from "../../responses/Site/CartInvoiceInformationResponse";
import {CartParticipantInformationResponse} from "../../responses/Site/CartParticipantInformationResponse";

export enum CheckoutStep {
    Delivery = 0,
    Invoice = 1,
    Participants = 2
}

export const useCheckoutStore = defineStore('checkout', () => {
    const step: Ref<Number> = ref(0);
    const deliveryInformationForm: Ref<CartDeliveryInformationResponse> = ref({
        is_participant: 0,
        create_account: false,
        company: '',
        salutation: '',
        first_name: '',
        insertion: '',
        last_name: '',
        postal: '',
        city: '',
        country: '',
        street: '',
        house_number: '',
        house_number_addition: '',
        address_line_1: '',
        address_line_2: '',
        email: '',
        phone: '',
        payment: '0',
        password: '',
        password_confirmation: '',
    });
    const invoiceInformationForm: Ref<CartInvoiceInformationResponse> = ref({
        company: '',
        salutation: '',
        first_name: '',
        insertion: '',
        last_name: '',
        postal: '',
        city: '',
        country: '',
        street: '',
        house_number: '',
        house_number_addition: '',
        address_line_1: '',
        address_line_2: '',
        email: '',
        phone: '',
        payment: '0',
    });

    const participantInformationForm: Ref<Array<CartParticipantInformationResponse>> = ref([]);
    const participantOccupationGroups: Ref<Array<any>> = ref([]);
    const participantOccupations: Ref<Array<any>> = ref([]);
    const occupationOtherErpId: Ref<String> = ref("");
    const occupationGroupOtherErpId: Ref<String> = ref("");
    const occupationGroupNoneErpId: Ref<String> = ref("");
    const loading: Ref<Boolean> = ref(false);
    const loggedIn: Ref<Boolean> = ref(false);
    const salutationOptions: Ref<Object> = ref({});
    const countryOptions: Ref<Object> = ref({});
    const cartStore = useCartStore();

    const updateDeliveryInformationRequest: UnwrapNestedRefs<{
        timeout: number | null
        abort: AbortController | null
    }> = reactive({
        timeout: null,
        abort: null,
    });
    const updateInvoiceInformationRequest: UnwrapNestedRefs<{
        timeout: number | null
        abort: AbortController | null
    }> = reactive({
        timeout: null,
        abort: null,
    });
    const updateParticipantInformationRequest: UnwrapNestedRefs<{
        timeout: number | null
        abort: AbortController | null
    }> = reactive({
        timeout: null,
        abort: null,
    });

    const setStep = (newStep) => {
        step.value = newStep;
        sessionStorage.setItem('checkout-step', step.value.toString());
    }
    const updateDeliveryInformation = async <T>(): Promise<T> => {
        return new Promise<T>(async (resolve, reject): Promise<void> => {
            let payload = {
                'cart': cartStore.getUuid()
            };

            loading.value = true;
            if (updateDeliveryInformationRequest.abort !== null) {
                updateDeliveryInformationRequest.abort.abort()
            }

            const abortController: AbortController = new AbortController()
            updateDeliveryInformationRequest.abort = abortController;

            try {
                const config: AxiosRequestConfig = {
                    signal: abortController.signal
                }

                const response: void | AxiosResponse<T> = await api().post(
                    '/site/cart/information/update-delivery-information/',
                    _.merge(payload, {_method: "PUT" }, deliveryInformationForm.value),
                    config
                ).then(response => {
                    invoiceInformationForm.value.payment = deliveryInformationForm.value.payment;
                    deliveryInformationForm.value.password = '';
                    deliveryInformationForm.value.password_confirmation = '';
                    if(deliveryInformationForm.value.payment === "0"){
                        prefillInvoiceForm();
                    }
                    resolve(response);
                }).catch(error => {
                    reject(error.response.data);
                });

                loading.value = false;
            } catch (e) {
                reject(e)
            }
        });
    }

    const updateInvoiceInformation = async <T>(): Promise<T> => {
        return new Promise<T>(async (resolve, reject): Promise<void> => {
            let payload = {
                'cart': cartStore.getUuid()
            };

            loading.value = true;
            if (updateInvoiceInformationRequest.abort !== null) {
                updateInvoiceInformationRequest.abort.abort()
            }

            const abortController: AbortController = new AbortController()
            updateInvoiceInformationRequest.abort = abortController;

            try {
                const config: AxiosRequestConfig = {
                    signal: abortController.signal
                }

                const response: void | AxiosResponse<T> = await api().post(
                    '/site/cart/information/update-invoice-information/',
                    _.merge(payload, {_method: "PUT" }, invoiceInformationForm.value),
                    config
                ).then(response => {
                    initializeParticipantForms().then(() => {
                        resolve(response);
                    });
                }).catch(error => {
                    reject(error.response.data);
                });

                loading.value = false;
            } catch (e) {
                reject(e)
            }
        });
    }

    const updateParticipantInformation = async <T>(): Promise<T> => {
        return new Promise<T>(async (resolve, reject): Promise<void> => {
            let payload = {
                'cart': cartStore.getUuid(),
                'participants': participantInformationForm.value
            };

            loading.value = true;
            if (updateParticipantInformationRequest.abort !== null) {
                updateParticipantInformationRequest.abort.abort()
            }

            const abortController: AbortController = new AbortController()
            updateParticipantInformationRequest.abort = abortController;

            try {
                const config: AxiosRequestConfig = {
                    signal: abortController.signal
                }

                const response: void | AxiosResponse<T> = await api().post(
                    '/site/cart/information/update-participant-information/',
                    _.merge(payload, {_method: "PUT" }),
                    config
                ).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error.response.data.errors);
                });

                loading.value = false;
            } catch (e) {
                reject(e)
            }
        });
    }

    const initialize = async <T>(email = ''): Promise<T> => {
        return new Promise<T>(async (resolve, reject): Promise<void> => {
            try {
                let payload = {
                    'cart': window.cart_uuid, //TODO lifecycle of stores??? Cannot use cart.cart_uuid?
                    'email': email,
                };
                const response: void | AxiosResponse<T> = await api().post(
                    '/site/cart/information/retrieve-address-forms/',
                    payload
                ).then(response => {
                    salutationOptions.value = response.data.salutationOptions;
                    countryOptions.value = response.data.countryOptions;
                    loggedIn.value = response.data.loggedIn;
                    participantOccupationGroups.value = response.data.occupationGroups;
                    participantOccupations.value = response.data.occupations;
                    occupationOtherErpId.value = response.data.occupationOtherId;
                    occupationGroupOtherErpId.value = response.data.occupationGroupOtherId;
                    occupationGroupNoneErpId.value = response.data.occupationGroupNoneId;
                    if(response.data.data.hasOwnProperty('delivery')) {
                        deliveryInformationForm.value = response.data.data.delivery;
                    }
                    if(response.data.data.hasOwnProperty('invoice')) {
                        invoiceInformationForm.value = response.data.data.invoice;
                    }
                    resolve(response);
                }).catch(error => {
                    reject(error.response.data);
                });
            }
            catch (e) {
                reject(e)
            }
        });
    }

    const initializeParticipantForms = async <T>(): Promise<T> => {
        return new Promise<T>(async (resolve, reject): Promise<void> => {
            try {
                let payload = {
                    'cart': window.cart_uuid
                };
                const response: void | AxiosResponse<T> = await api().post(
                    '/site/cart/information/initialize-participant-forms/',
                    payload
                ).then(response => {
                    participantInformationForm.value = response.data;
                    resolve(response);
                }).catch(error => {
                    reject(error.response.data);
                });
            }
            catch (e) {
                reject(e)
            }
        });
    }

    const checkOrderStatus = async <T>(payload): Promise<T> => {
        return new Promise<T>(async (resolve, reject): Promise<void> => {
            try {
                const response: void | AxiosResponse<T> = await api().post(
                    '/site/order/status/',
                    payload
                ).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error.response.data);
                });
            }
            catch (e) {
                reject(e)
            }
        });
    }

    const prefillInvoiceForm = () => {
        invoiceInformationForm.value.company = deliveryInformationForm.value.company;
        invoiceInformationForm.value.salutation = deliveryInformationForm.value.salutation;
        invoiceInformationForm.value.insertion = deliveryInformationForm.value.insertion;
        invoiceInformationForm.value.first_name = deliveryInformationForm.value.first_name;
        invoiceInformationForm.value.last_name = deliveryInformationForm.value.last_name;
        invoiceInformationForm.value.address_line_1 = deliveryInformationForm.value.address_line_1;
        invoiceInformationForm.value.street = deliveryInformationForm.value.street;
        invoiceInformationForm.value.house_number = deliveryInformationForm.value.house_number;
        invoiceInformationForm.value.house_number_addition = deliveryInformationForm.value.house_number_addition;
        invoiceInformationForm.value.postal = deliveryInformationForm.value.postal;
        invoiceInformationForm.value.city = deliveryInformationForm.value.city;
        invoiceInformationForm.value.email = deliveryInformationForm.value.email;
        invoiceInformationForm.value.phone = deliveryInformationForm.value.phone;
        invoiceInformationForm.value.country = deliveryInformationForm.value.country;
    }

    return {
        step,
        setStep,
        loading,
        loggedIn,
        salutationOptions,
        countryOptions,
        updateDeliveryInformation,
        updateInvoiceInformation,
        updateParticipantInformation,
        initialize,
        initializeParticipantForms,
        deliveryInformationForm,
        invoiceInformationForm,
        participantInformationForm,
        participantOccupationGroups,
        participantOccupations,
        checkOrderStatus,
        occupationOtherErpId,
        occupationGroupOtherErpId,
        occupationGroupNoneErpId,
    }
});
