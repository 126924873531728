import Pagination, {PaginationLink, PaginationMeta} from "../types/Pagination";

export default class PaginationWindow {
    private readonly links: PaginationLink[];
    private readonly previous: PaginationLink | null;
    private readonly next: PaginationLink | null;

    private sliderPlaceholder: string = '...';
    private offset: number = 5; // 5 is best as it is the minimal CLS (Cumulative Layout Shift)

    constructor(
        private readonly meta: PaginationMeta,
    ) {
        this.links = [...meta.links]; // Make sure to unlink it otherwise it will add wierd behavior by modifying parent array
        this.previous = this.links.shift() ?? null;
        this.next = this.links.pop() ?? null;
    }

    public setOffset(offset: number): PaginationWindow {
        this.offset = offset;
        return this;
    }

    public setSliderPlaceholder(value: string): PaginationWindow {
        this.sliderPlaceholder = value;
        return this;
    }

    public process(): PaginationLink[] {

        // When amount of pages is lower than offset
        if (this.links.length <= this.offset) {
            return this.getSliderWithoutSlider();
        }

        // When in begin of pagination window
        if (this.meta.current_page <= this.offset - 1) {
            return this.getSliderTooCloseToBeginning();
        }

        // When in end of pagination window
        if (this.meta.current_page >= (this.meta.last_page - this.offset + 1)) {
            return this.getSliderTooCloseToEnding();
        }

        return this.getFullSlider();
    }

    private sliderItem(): PaginationLink {
        return {
            label: this.sliderPlaceholder,
            url: null,
            active: false
        };
    }



    private getSliderWithoutSlider(): PaginationLink[] {
        const output: PaginationLink[] = [];
        if (this.previous) output.push(this.previous); // Previous button

        output.push(...this.links.slice(-this.offset)); // Last x items

        if (this.next) output.push(this.next); // Next button

        return output;
    }

    private getSliderTooCloseToBeginning(): PaginationLink[] {
        const output: PaginationLink[] = [];
        if (this.previous) output.push(this.previous); // Previous button

        output.push(...this.links.slice(0, this.offset)); // First x items

        output.push(this.sliderItem());

        output.push(...this.links.slice(-1)); // Last page

        if (this.next) output.push(this.next); // Next button

        return output;
    }

    private getSliderTooCloseToEnding(): PaginationLink[] {
        const output: PaginationLink[] = [];
        if (this.previous) output.push(this.previous); // Previous button

        output.push(...this.links.slice(0, 1)); // First page

        output.push(this.sliderItem());

        output.push(...this.links.slice(-this.offset)); // Last x items

        if (this.next) output.push(this.next); // Next button

        return output;
    }

    private getFullSlider(): PaginationLink[] {
        const output: PaginationLink[] = [];
        if (this.previous) output.push(this.previous); // Previous button

        output.push(...this.links.slice(0, 1)); // First page

        output.push(this.sliderItem());

        output.push(this.links[this.meta.current_page - 2]) // Previous Page
        output.push(this.links[this.meta.current_page - 1]) // Current page
        output.push(this.links[this.meta.current_page]) // Next page

        output.push(this.sliderItem());

        output.push(...this.links.slice(-1)); // Last page

        if (this.next) output.push(this.next); // Next button

        return output;
    }
}
