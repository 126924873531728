<script setup lang="ts">
import {useCartStore} from "../../stores/cart";

const cart = useCartStore();
</script>

<template>
    <a href="/cart/" class="flex items-center justify-center h-10 w-10 lg:h-12 lg:w-12 bg-secondary-50 hover:bg-secondary-100 rounded-full">
        <div class="absolute -top-2 -right-2 z-10 bg-secondary-950 text-white text-xs text-center font-normal px-1 py-0.5 rounded-xl w-5 h-5">{{ cart.getItemCount() }}</div>
        <svg class="h-5 w-5 lg:h-6 lg:w-6" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" >
            <circle cx="8" cy="21" r="1"/>
            <circle cx="19" cy="21" r="1"/>
            <path d="M2.05 2.05h2l2.66 12.42a2 2 0 0 0 2 1.58h9.78a2 2 0 0 0 1.95-1.57l1.65-7.43H5.12"/>
        </svg>
    </a>
</template>
