<script setup lang="ts">
import {useFilterStore} from "../../stores/filter.ts";
import {computed, ref} from "vue";
import {label} from "../../mixins/translations";
import {TagCategoryResponse} from "../../../responses/TagCategoryResponse";
interface Props{
    tagCategory: TagCategoryResponse,
    initial: Object,
}
const props = defineProps<Props>();
const filterStore = useFilterStore();

const emit = defineEmits<{
    (e: 'updateFilter', {tag: String}): void;
}>();

const updateFilter = (tag) => {
    filterStore.addOrRemoveFilter('tag', tag);
}
const isChecked = (tag) => {
    return filterStore.hasFilterWithValue('tag', tag);
}

const sortedTags = computed(() => {
    return [...props.tagCategory.tags].sort((a, b) => a.label.localeCompare(b.label));
});
</script>

<template>
    <div class="w-full">
        <h2 class="py-3 flex items-center justify-between lg:border-b-0 border-b border-gray-200 !m-0" @click="filterStore.toggleCategory(tagCategory.id)">
            <span>{{ tagCategory.name }}</span>
            <span class="inline-block lg:hidden" >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" :class="{'rotate-180': filterStore.checkedCategories.includes(tagCategory.id)}" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-down"><path d="m6 9 6 6 6-6"/></svg>
            </span>
        </h2>
        <div class="w-full flex flex-col " :class="{'inline-flex lg:block mt-2': filterStore.checkedCategories.includes(tagCategory.id), 'hidden lg:block': !filterStore.checkedCategories.includes(tagCategory.id)}">
            <nav class="flex flex-col text-base font-normal text-gray-700">
                <div v-for="(tag, index) in sortedTags" role="button"
                     class="flex items-center w-full p-0 leading-tight transition-all rounded-sm outline-none text-start hover:bg-secondary-50
                     hover:bg-opacity-80 hover:text-gray-900 focus:bg-secondary-50 focus:bg-opacity-80 focus:text-gray-900 active:bg-secondary-50
                     active:bg-opacity-80 active:text-gray-900">
                    <label class="flex items-center w-full py-1 cursor-pointer">
                        <div class="grid mr-3 place-items-center">
                            <div class="inline-flex items-center">
                                <label class="relative flex items-center p-0 rounded-full cursor-pointer">
                                    <input name="vertical-list" id="vertical-list-vue" type="checkbox" class="before:content[''] peer relative h-5 w-5
                                    cursor-pointer appearance-none rounded-sm border border-gray-300 text-primary
                                    transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12
                                    before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-secondary-500
                                    before:opacity-0 before:transition-opacity checked:border-primary checked:before:bg-primary hover:before:opacity-0 focus:ring-0"
                                        :value="tag.slug"
                                        :checked="isChecked(tag.slug)"
                                        @change="updateFilter(tag.slug)" />
                                    <span class="absolute text-primary transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                        <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" stroke="transparent" stroke-width="0" stroke-linecap="round" stroke-linejoin="round">
                                            <rect width="18" height="18" x="3" y="3" rx="2"/>
                                        </svg>
                                    </span>
                                </label>
                            </div>
                        </div>
                        <p class="block font-sans text-[0.9rem] antialiased leading-relaxed text-gray-700">
                            {{ tag.label }} <span class="rounded-full bg-secondary-100 px-2 py-0.5 text-xs text-foreground ml-1">{{ tag.product_count }}</span>
                        </p>
                    </label>
                </div>
            </nav>
        </div>
    </div>
</template>
