<script setup lang="ts">

import Error from "../Error.vue";
import {label} from "../../mixins/translations";
import {onMounted, ref, Ref} from "vue";
import {api} from "../../scripts/api";
import Button from "../../../backend/ui/Button.vue";
import {setRecaptchaKey} from "../../scripts/recapcha";
import Input from "../../../backend/ui/Input.vue"
import {Loader2Icon} from "lucide-vue-next";
import {DebtorContactResponse} from "../../../responses/Site/DebtorContactResponse";
interface Props {
    occupations: Array<string>,
    route?: String,
    user?: DebtorContactResponse|null,
    recaptchaKey?: string,
    formName?: string
}
const props = withDefaults(defineProps<Props>(), {
    route: '/site/form/newsletter/',
    formName: 'newsletterform'
});

const message: Ref<String> = ref('');
const errors: Ref<Object> = ref({});
const submitted: Ref<Boolean> = ref(false);
const loading: Ref<Boolean> = ref(false);

const form: Ref<Object> = ref({
    recaptcha: '',
    occupation: [],
});


onMounted(() => {
    //Prefil with optional user data
    if(props.user) {
        form.value = {
            ...form.value,
            email: props.user.email,
        }
    }

    setRecaptchaKey(props.recaptchaKey);
});

const handleForm = () => {
    loading.value = true;
    grecaptcha.ready(function() {
        grecaptcha.execute(props.recaptchaKey, {action: props.formName}).then(function(token) {
            form.value.recaptcha = token;
            api().post(props.route ,form.value)
                .then((response) => {
                    submitted.value = true;
                    message.value = response.data.message;
                }).catch((error) => {
                if(error.response) {
                    errors.value = error.response.data.errors ?? {};
                }
                if(error.response.data.message && error.response.status !== 422) {
                    message.value = error.response.data.message;
                }
                setRecaptchaKey(props.recaptchaKey);
            }).finally(() => {
                loading.value = false;
            });
        });
    });
}

</script>

<template>
    <div class="py-2">
        <div class="w-full text-lg text-medium tracking-relaxed text-black sm:text-lg text-center" v-if="message !== ''">
            <div class="container max-w-[360px] mx-auto">
                {{ message }}
            </div>
        </div>
        <div v-if="!submitted" class="flex flex-col gap-2 lg:gap-6">
            <div class="w-full flex flex-col lg:flex-row gap-2 lg:gap-6">
                <div class="w-full lg:w-5/12">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900">{{ label('site.account.first_name') }} <span class="text-red-500">*</span></label>
                    <input type="text" id="first_name" v-model="form.first_name" class="bg-white border border-secondary-100 focus:shadow-md focus:shadow-gray-200 text-gray-900 text-sm rounded focus:ring-secondary-200 focus:border-secondary-100 block w-full p-3" placeholder="" required />
                    <Error v-if="errors.hasOwnProperty('first_name')" :error="errors?.first_name[0]"></Error>
                </div>
                <div class="w-full lg:w-2/12">
                    <label for="insertion" class="block mb-2 text-sm font-medium text-gray-900">{{ label('site.account.insertion') }}</label>
                    <input type="text" id="insertion" v-model="form.insertion" class="bg-white border border-secondary-100 focus:shadow-md focus:shadow-gray-200 text-gray-900 text-sm rounded focus:ring-secondary-200 focus:border-secondary-100 block w-full p-3" placeholder="" />
                    <Error v-if="errors.hasOwnProperty('suffix')" :error="errors?.insertion[0]"></Error>
                </div>
                <div class="w-full lg:w-5/12">
                    <label for="last_name" class="block mb-2 text-sm font-medium text-gray-900">{{ label('site.account.last_name') }} <span class="text-red-500">*</span></label>
                    <input type="text" id="last_name" v-model="form.last_name" class="bg-white border border-secondary-100 focus:shadow-md focus:shadow-gray-200 text-gray-900 text-sm rounded focus:ring-secondary-200 focus:border-secondary-100 block w-full p-3" placeholder="" required />
                    <Error v-if="errors.hasOwnProperty('last_name')" :error="errors?.last_name[0]"></Error>
                </div>
            </div>
            <div>
                <label for="email" class="block mb-2 text-sm font-medium text-gray-900">{{ label('site.account.email') }} <span class="text-red-500">*</span></label>
                <input type="email" id="email" v-model="form.email" class="bg-white border border-secondary-100 focus:shadow-md focus:shadow-gray-200 text-gray-900 text-sm rounded focus:ring-secondary-200 focus:border-secondary-100 block w-full p-3" placeholder="" required />
                <Error v-if="errors.hasOwnProperty('email')" :error="errors?.email[0]"></Error>
            </div>
            <div class="w-full">
                <label for="occupation" class="block mb-2 text-sm font-medium text-gray-900">{{ label('site.form.newsletter.occupation') }} <span class="text-red-500">*</span></label>
                <div class="w-full columns-1 lg:columns-2">
                    <div v-for="(occupation, index) in occupations">
                        <label class="flex items-center w-full py-1 cursor-pointer">
                            <div class="grid mr-3 place-items-center">
                                <div class="inline-flex items-center">
                                    <label class="relative flex items-center p-0 rounded-full cursor-pointer">
                                        <input name="vertical-list" id="vertical-list-vue" type="checkbox" class="before:content[''] peer relative h-5 w-5
                                            cursor-pointer appearance-none rounded-sm border border-gray-300 text-primary
                                            transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12
                                            before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-secondary-500
                                            before:opacity-0 before:transition-opacity checked:border-primary checked:before:bg-primary hover:before:opacity-0 focus:ring-0"
                                               :value="occupation"
                                                v-model="form.occupation" />
                                        <span class="absolute text-primary transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                        <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" stroke="transparent" stroke-width="0" stroke-linecap="round" stroke-linejoin="round">
                                            <rect width="18" height="18" x="3" y="3" rx="2"/>
                                        </svg>
                                    </span>
                                    </label>
                                </div>
                            </div>
                            <p class="block font-sans text-sm antialiased leading-relaxed text-gray-700">
                                {{ occupation }}
                            </p>
                        </label>
                    </div>
                </div>
                <Error v-if="errors.hasOwnProperty('occupation')" :error="errors?.occupation[0]"></Error>
            </div>
            <button :disabled="loading" class="ml-auto mt-4 sm:flex flex-col place-items-end inline-block cursor-pointer rounded-full bg-primary-500 text-white hover:bg-primary-700 py-3 px-8 text-md font-bold text-muted-foreground" @click="handleForm">
                <Loader2Icon v-if="loading" class="w-5 h-5 animate-spin"></Loader2Icon>
                <span v-else v-text="label('site.form.newsletter.submit')"></span>
            </button>
        </div>
    </div>
</template>
