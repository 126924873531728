<script setup lang="ts">
import {label} from "../../mixins/translations.js";
import {AddressResponse} from "../../../responses/AddressResponse";
import AddressInput from "./AddressInput.vue";
import {Ref, ref} from "vue";
import {useAddressStore} from "../../stores/address";
import {DialogTrigger} from "radix-vue";

interface Props {
    addresses?: Array<AddressResponse>
    countries: { [key: string]: string },
    type?: string,
}

const props = withDefaults(defineProps<Props>(),{
    addresses: [],
    countries: [],
    type: 'delivery'
})


const addressList: Ref<Array<AddressResponse>> = ref(props.addresses)
const addressStore = useAddressStore()


const createAddress: Ref<AddressResponse|object> = ref({ type: props.type})
const createErrors = ref({});
const openCreate = ref(false);

const updateAddress = async (address, newValue) => {
    createAddress.value = newValue;
}
const saveAddress = async (address) => {
    let response = await addressStore.createAddress(address);
    if(response.message === 'success' && response.address) {
        addressList.value.push(response.address);
        createAddress.value = { type: props.type};
        openCreate.value = false;
    } else {
        createErrors.value = response.errors;
    }
}

const removeFromList = (id) => {
    addressList.value = addressList.value.filter(function(item) {
        return item.id !== id;
    });
}


</script>

<template>
    <div class="w-full max-h-full mt-5">
        <div class="grid sm:grid-cols-2 gap-2">
              <AddressItem :current="address" :countries="countries" v-for="address in addressList" @delete="(value) => removeFromList(value)"></AddressItem>
        </div>
    </div>
</template>
