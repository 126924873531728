<script setup lang="ts">
import {ref, Ref} from "vue";

interface Props {
    value: Number,
}
const props = defineProps<Props>();
const value: Ref<Number> = ref(props.value);

const emit = defineEmits<{
    (e: 'input', value: Number): void;
}>();

const updateInput = () => {
    let amount = value.value;
    if (amount <= 0) amount = 1;
    emit('input', amount);
}
const incrementAmount = () => {
    value.value++;
    updateInput();
}
const decrementAmount = () => {
    value.value--;
    if (value.value <= 0) value.value = 1;
    updateInput();
}
</script>

<template>
    <div class="relative flex items-center max-w-[8rem]">
        <button @click.prevent="decrementAmount" class="bg-white hover:bg-gray-200 border border-gray-300 rounded-s-lg p-3 h-14 focus:ring-gray-100 focus:ring-2 focus:outline-none" type="button">
            <svg aria-hidden="true" class="w-3 h-3 text-gray-900" fill="none" viewBox="0 0 18 2" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1h16" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            </svg>
        </button>
        <input class="bg-white border-t border-b border-gray-300 h-14 text-center text-black text-sm focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5" required type="text"  v-model="value" @input="updateInput" />
        <button @click.prevent="incrementAmount" id="increment-button" class="bg-white hover:bg-gray-200 border border-gray-300 rounded-e-lg p-3 h-14 focus:ring-gray-100 focus:ring-2 focus:outline-none" data-input-counter-increment="quantity-input" type="button">
            <svg aria-hidden="true" class="w-3 h-3 text-gray-900" fill="none" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 1v16M1 9h16" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            </svg>
        </button>
    </div>
</template>
