<script setup lang="ts">
import ProductFilter from "./ProductFilter.vue";
import 'nouislider/dist/nouislider.css';
import {useFilterStore} from "../../stores/filter";
import {FilterXIcon} from "lucide-vue-next";
import {FilterResponse} from "../../../responses/FilterResponse";
import {label} from "../../mixins/translations";
import {TagCategoryResponse} from "../../../responses/TagCategoryResponse";
import TagFilter from "./TagFilter.vue";
import DateFilter from "./DateFilter.vue";
import SearchFilter from "./SearchFilter.vue";

interface Props{
    filters: Array<FilterResponse>
    propertyFilters: Array<FilterResponse>
    initial: Object
}
const props = defineProps<Props>();
const filterStore = useFilterStore();


const emit = defineEmits<{
    (e: 'updateFilter', filterOptionSlug): void;
}>();

const updateFilter = (filterOption) => {
    emit('updateFilter', filterOption);
}

</script>

<template>
    <div class="w-full">
        <div class="w-full mb-5" v-show="filterStore.getFilterCount() > 0">
            <button @click.prevent="filterStore.resetFilters()" class="w-full flex justify-center px-8 py-4 text-white font-bold bg-secondary-950 text-white hover:bg-secondary-900 rounded-full">
                <div class="flex items-center justify-center">
                    <FilterXIcon class="h-4 w-4"></FilterXIcon>
                    <span class="ml-2">{{ label('site.filter.remove') }}</span>
                </div>
            </button>
        </div>

        <SearchFilter @update-filter="updateFilter" />

        <ProductFilter @update-filter="updateFilter" :filter="filter" :initial="initial" filter-type="filter" v-for="filter in filters"></ProductFilter>
        <ProductFilter @update-filter="updateFilter" :filter="filter" :initial="initial" filter-type="property" v-for="filter in propertyFilters"></ProductFilter>

        <TagFilter v-for="tagCategory in filterStore.tagCategories" :initial="initial" :tag-category="tagCategory"></TagFilter>
        <DateFilter @update-filter="updateFilter"></DateFilter>
    </div>
</template>

