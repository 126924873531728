<script setup lang="ts">
import 'vue3-carousel/dist/carousel.css'
import {Carousel, Slide, Navigation, Pagination} from 'vue3-carousel'
import {ProductResponse} from "../../../responses/ProductResponse";
import Product from "./Product.vue";
import {EqualHeights} from '@morev/equal-heights';

interface Props {
    products: ProductResponse[]
}

const props = defineProps<Props>()

const config = {
    itemsToShow: 1,
    snapAlign: 'start',
    wrapAround: props.products.length > 2,
    breakpoints: {
        800: {
            itemsToShow: 2,
            snapAlign: 'start',
        },
        1200: {
            itemsToShow: 3,
            snapAlign: 'start',
        },
        1600: {
            itemsToShow: 3,
            snapAlign: 'start',
        }
    }
}

const equalHeights = new EqualHeights({});
equalHeights.add([
    {selector: '.productSlide', options: {}},
]);
</script>

<template>
    <div class="carousel__wrapper">
        <Carousel v-bind="config">
            <Slide v-for="product in products" :key="product.id" class="px-3">
                <Product :product="product" layout="grid" class="px-0 px-6"></Product>
            </Slide>

            <template #addons v-if="products.length > 3">
                <Navigation/>
            </template>
        </Carousel>
    </div>
</template>

<style lang="postcss">
.carousel__wrapper {
    .carousel__prev {
        left: -2rem;
    }

    .carousel__next {
        right: -2rem;
    }
}
</style>
