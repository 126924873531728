<script setup lang="ts">
import {ref, watch} from "vue";
import {useCartStore} from "../../stores/cart";
import {find} from 'lodash';

const cartStore = useCartStore();
let currentPrice = ref({}); //todo price response?
watch(
    () => cartStore.addToCartQuantity,
    () => {
        updatePrice();
    }
)
watch(
    () => cartStore.addToCartSelectedVariant,
    () => {
        updatePrice();
    }
)
const updatePrice = () => {
    currentPrice.value = find(cartStore.addToCartSelectedVariant.prices, function(price){
        return price.quantity <= cartStore.addToCartQuantity;
    });
}
</script>

<template>
    <p class="font-bold text-xl text-foreground" v-if="currentPrice">{{ $root.formatPrice(currentPrice.price_incl) }}</p>
</template>

