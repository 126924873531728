<script setup lang="ts">
import {onMounted, ref} from "vue";
import {label} from "../../mixins/translations";
import {useCartStore} from "../../stores/cart";

const open = ref(false);
const cartStore = useCartStore();

const afterAdding = () => {
    open.value = true;
}

onMounted(() => {
    cartStore.events.on('afterAdding', afterAdding);
});
</script>
<template>
    <Modal :open="open !== false" @updateOpen="open = !open;" width-class="max-w-[600px]">
        <template #trigger-wrapper>&nbsp;</template>
        <template #title>
            <div class="w-full">
                <div class="text-xl w-full text-primary font-bold text-center">{{
                        label('site.product.popup.title')
                    }}
                </div>
            </div>
        </template>
        <template #description>
            <div class="space-y-6">
                <div class="paragraph text-base font-normal text-black text-center mt-3">
                    {{ label('site.product.popup.content') }}
                </div>

                <div class="flex flex-col lg:flex-row gap-4 text-center justify-center">
                    <button @click.prevent="open = false;"
                            class="w-full px-4 py-2 lg:py-4 cursor-pointer rounded-full flex items-center justify-center font-bold border border-secondary-light-border text-secondary-950 bg-secondary-light hover:bg-secondary-200 transition-all duration-500 z-20"
                    >
                        <span>{{ label('site.product.popup.continue_shopping') }}</span>
                    </button>
                    <a href="/cart/"
                       class="w-full px-4 py-2 lg:py-4 cursor-pointer rounded-full font-bold flex items-center justify-center text-white bg-secondary-950 hover:bg-secondary-900 transition-all duration-500"
                    >
                        {{ label('site.product.popup.cart') }}
                    </a>
                </div>
            </div>
        </template>
    </Modal>
</template>
