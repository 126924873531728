<script setup lang="ts">
import {tw} from "../../utils/tailwind";

interface SkeletonProps {
    class?: HTMLAttributes['class']
}

const props = defineProps<SkeletonProps>()
</script>

<template>
    <div :class="tw('animate-pulse rounded-md bg-secondary-200/50 h-8 flex justify-center items-center', props.class)"><slot/></div>
</template>
