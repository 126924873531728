<script setup lang="ts">
import CartListItem from './CartListItem.vue';
import {label} from "../../mixins/translations.js";
import {useCartStore} from "../../stores/cart";

const cart = useCartStore();
</script>

<template>
    <div class="space-y-4" v-if="cart.cartItems.length > 0">
        <CartListItem :cart-item="cartItem" :key="cartItem.model.id" v-for="cartItem in cart.cartItems"/>
    </div>
    <div class="py-6 text-gray-900" v-else>
        {{ label('site.cart.overview.empty') }}
    </div>
</template>
