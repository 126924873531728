<script setup lang="ts">

import {ref} from "vue";
import {ProductResponse} from "../../../responses/ProductResponse";
import {ProductImageResponse} from "../../../responses/ProductImageResponse";

interface Props{
    images: ProductImageResponse[]
    product: ProductResponse
}
const props = defineProps<Props>();
const selectedImage = ref(props.images[0]);
</script>
<template>
    <div v-if="images.length">
        <div class="flex gap-5">
            <div class="flex flex-col w-40 items-start justify-start gap-4 pr-1 scrollBar overflow-y-auto">
                <div class="w-fit h-fit cursor-pointer rounded-xl overflow-hidden border-2 border-background-100" v-for="image in props.images">
                    <img :alt="image.alt_text ?? product.name" :src="image.image_path" @click="selectedImage = image;"/>
                </div>
            </div>
            <div class="rounded-xl overflow-hidden relative w-fit">
                <img :alt="selectedImage.alt_text ?? product.name" :src="selectedImage.image_path" />
            </div>
        </div>
    </div>
    <div v-else>
        <div class="rounded-xl overflow-hidden relative w-fit">
            <img :alt="product.name" :src="product.thumbnail.image_path" />
        </div>
    </div>
</template>
