// stores/counter.js
import {defineStore} from 'pinia'
import {computed, ComputedRef, reactive, ref, Ref, UnwrapNestedRefs, watch} from "vue";
import {useDebounceFn, useStorage} from "@vueuse/core";
import {AxiosRequestConfig, AxiosResponse} from "axios";

//TODO: deze ook in frontend? of deze gebruiken?
import {api} from "../scripts/api.ts";

import { Loader } from "@googlemaps/js-api-loader"
import _ from "lodash";
// interfaces

export const useAddressStore = defineStore('address', () => {

    const loader = new Loader({
        version: "weekly",
        apiKey: import.meta.env.VITE_GOOGLE_API, //standard vite env implementation
    });

    const createAddress = async (data) : Promise<T> => {
        return new Promise<T>(async (resolve, reject): Promise<void> => {
             await api().post('/site/address/store/',data)
                 .then((response) => {
                     resolve(response.data);
                 }).catch((error) => {
                     if(error.response) {
                         resolve(error.response.data)
                     } else {
                         reject(error)
                     }
                });
        });
    }

    const updateAddress = async (id,data): Promise<T> => {
        return new Promise<T>(async (resolve, reject): Promise<void> => {
            await api().post('/site/address/' + id + '/update/', _.merge(data, {_method: "PUT"}))
                .then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    if(error.response) {
                        resolve(error.response.data)
                    } else {
                        reject(error)
                    }
                });
        });
    }

    const deleteAddress = async (id):  Promise<T> => {
        return new Promise<T>(async (resolve, reject): Promise<void> => {
            await api().post('/site/address/' + id + '/delete/',{_method: "DELETE"})
                .then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    if(error.response) {
                        resolve(error.response.data)
                    } else {
                        reject(error)
                    }
                });
        });

    }

    const getSearchResults = useDebounceFn(async <T>(input: string): Promise<T> => {
        return new Promise<T>(async (resolve, reject): Promise<void> => {
            try {
                //https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
                const Places = await loader.importLibrary('places');
                const service = new Places.AutocompleteService

                const response = await service.getPlacePredictions({
                    'input': input,
                    'locationBias' : 'IP_BIAS',
                    'componentRestrictions': {country: ["nl", "be"]},
                    'types': [ //om alleen adressen te krijgen
                        'street_address',
                        'premise',
                        'post_box'
                    ]
                }, function (placesResponse){
                    //iets mee doen of negeren?
                })
                resolve(response.predictions);
            } catch (e) {
                reject(e)
            }
        })
    }, 500)

    const getAddressDetails = async <T>(places_id: string, splitAddressCountries:Array<string> = []): Promise<T> => {
        return new Promise<T>(async (resolve, reject): Promise<void> => {
            try {
                //https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
                //Map faken want dat moet schijnbaar
                const Maps = await loader.importLibrary('maps');
                let map = new Maps.Map(document.createElement('div'));

                const Places = await loader.importLibrary('places');
                const service = new Places.PlacesService(map)

                const response = await service.getDetails({
                    placeId: places_id,
                    fields: ['address_components', 'formatted_address']
                }, function (placesResponse, status ){
                    let returnValue = {};

                    for(const[key, value] of Object.entries(placesResponse.address_components)) {
                        if(value.types.includes('country')) returnValue.country = value.short_name; //TODO: type check
                        if(value.types.includes('street_number')) {
                            let split = value.long_name.split(/^(\d+)(.*)$/);
                            returnValue.house_number = split[1];
                            returnValue.house_number_addition = split[2];
                        }
                        if(value.types.includes('route')) returnValue.street = value.long_name;
                        if(value.types.includes('locality') || value.types.includes('postal_town')) returnValue.city = value.long_name;
                        if(value.types.includes('postal_code')) returnValue.postal = value.long_name;
                    }
                    if(!splitAddressCountries.includes(returnValue.country)) {
                        returnValue.address_line_1 = placesResponse.formatted_address.split(',')[0]
                    }
                    resolve(returnValue);
                })
            } catch (e) {
                reject(e)
            }
        })
    }

    return {
        getSearchResults,
        getAddressDetails,
        createAddress,
        updateAddress,
        deleteAddress
    }
    }
)
