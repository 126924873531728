<script setup lang="ts">
import {ProductResponse} from "../../../responses/ProductResponse";
import {first} from "lodash";
import {label} from "../../mixins/translations.js";
import {HTMLAttributes, Ref, ref, computed, ComputedRef} from "vue";
import {tw} from "../../../utils/tailwind";
import {ProductType} from "../../../enums/ProductType";

interface Props {
    product: ProductResponse,
    layout?: string
    skeleton?: boolean
    class?: HTMLAttributes['class'],
}
const props = withDefaults(defineProps<Props>(), {
    skeleton: false,
    layout: 'list'
});

const isProduct = computed(() => {
    return props.product.type === ProductType.Product;
})

const firstVariant = computed(() =>  {
    return first(props.product.variants);
});

const productImageSrc: ComputedRef<string> = computed(() => {
 return props.product.thumbnail?.image_path + '?fm=webp&q=90&w=400'
})

const productImageAlt: ComputedRef<string> = computed(() => {
 return props.product.thumbnail?.alt_text || props.product?.name || ''
})

</script>
<template>
    <div :class="tw('w-full h-full flex flex-col lg:flex-row gap-10 border-b border-gray-200 py-3 lg:py-10', props.class)" v-if="layout !== 'grid'">
        <a :href="'/' + product.slug + '/'" class="w-full lg:w-1/4">
            <img
                :src="productImageSrc"
                :alt="productImageAlt"
                class="object-cover rounded-t-2xl lg:rounded-l-2xl"
                loading="lazy"
            />
        </a>

        <div class="w-full lg:w-3/4">
            <a :href="'/' + product.slug + '/'">
                <h3 class="text-xl font-bold flex flex-row justify-between text-secondary-900">
                    <span>{{ product.name }}</span>
                </h3>
            </a>
            <span class="flex text-base text-left font-normal mt-1" v-show="product.alternative_name !== null">
                {{ product.alternative_name }}
            </span>
            <span class="flex text-lg font-medium mt-3">
                {{ $root.formatPrice(product.price_incl) }}
            </span>
            <ul class="w-full text-base list-none py-3 columns-1 lg:columns-2">
                <li class="flex" v-show="!isProduct">
                    <span class="mr-4"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#cc006c" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="h-6 w-6 lucide lucide-calendar-days"><path d="M8 2v4"></path><path d="M16 2v4"></path><rect width="18" height="18" x="3" y="4" rx="2"></rect><path d="M3 10h18"></path><path d="M8 14h.01"></path><path d="M12 14h.01"></path><path d="M16 14h.01"></path><path d="M8 18h.01"></path><path d="M12 18h.01"></path><path d="M16 18h.01"></path></svg></span>
                    <span class="font-bold">
                        {{ product.meeting_amount }} {{ product.meeting_amount > 1 ? label('site.product.meetings') : label('site.product.meeting') }}
                    </span>
                </li>
                <li class="flex mt-2" v-show="product.target_audience !== null && product.target_audience !== ''">
                    <span class="mr-4"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#cc006c" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="h-6 w-6 lucide lucide-user-check"><path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><polyline points="16 11 18 13 22 9"></polyline></svg></span>
                    <span>{{ product.target_audience }}</span>
                </li>
                <li class="flex mt-2" v-show="!isProduct">
                    <span class="mr-4"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#cc006c" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="h-6 w-6 lucide lucide-calendar-clock"><path d="M21 7.5V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h3.5"></path><path d="M16 2v4"></path><path d="M8 2v4"></path><path d="M3 10h5"></path><path d="M17.5 17.5 16 16.3V14"></path><circle cx="16" cy="16" r="6"></circle></svg></span>
                    <span v-if="firstVariant?.formatted_start_date">{{ firstVariant?.formatted_start_date }}</span>
                    <span v-else-if="product.type === ProductType.SelfStudy && product.variants_count >= 1">{{ label('site.product.e-learning.date') }}</span>
                    <span v-else-if="product.type === ProductType.SelfStudy">E-learning</span>
                    <span v-else>{{ label('site.product.unknown_date')}}</span>

                    <a :href="'/' + product.slug + '#datums'" v-if="product.variants_count > 1" v-show="!isProduct">
                        <span class="py-1 px-2 rounded-xl lg:rounded-full bg-gray-100 text-xs font-semibold text-black ml-0 lg:ml-2 flex items-center">+ {{ product.variants_count - 1}} data</span>
                    </a>
                </li>
                <li class="flex mt-2" v-show="product.points > 0 && !isProduct" >
                    <span class="mr-4"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#cc006c" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="h-6 w-6 lucide lucide-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"/></svg></span>
                    <span>{{ product.points ?? 'Onbekend aantal' }} {{ label('site.product.points') }}</span>
                </li>
            </ul>
            <a :href="'/' + product.slug + '/'" class="2xl:w-1/2 mt-6 flex justify-center lg:px-16 lg:py-4 px-8 py-4 text-white font-bold bg-primary hover:bg-primary-600 rounded-full focus:bg-primary-600 focus:outline-none">
                <span v-if="product.type === ProductType.Product">{{ label('site.product.more_information_product') }}</span>
                <span v-else>{{ label('site.product.more_information') }}</span>
            </a>
        </div>
    </div>
    <div :class="tw('w-full flex flex-col p-6 shadow-lg rounded-xl bg-background', props.class)" v-else>
        <a :href="'/' + product.slug + '/'" class="w-full">
            <img
                :src="productImageSrc"
                :alt="productImageAlt"
                class="object-cover rounded-t-2xl lg:rounded-l-2xl"
                loading="lazy"
            />
        </a>

        <div class="w-full productSlide mt-10">
            <a :href="'/' + product.slug + '/'">
                <h3 class="text-xl text-left font-bold flex flex-row justify-between text-secondary-900">
                    <span>{{ product.name }}</span>
                </h3>
            </a>
            <span class="flex text-base text-left font-normal mt-1" v-show="product.alternative_name !== null">
                {{ product.alternative_name }}
            </span>
            <span class="flex text-lg font-medium mt-3">
                {{ $root.formatPrice(product.price_incl) }}
            </span>
            <ul class="w-full text-base list-none py-3 columns-1">
                <li class="flex" v-show="!isProduct">
                    <span class="mr-4"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#cc006c" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="h-6 w-6 lucide lucide-calendar-days"><path d="M8 2v4"></path><path d="M16 2v4"></path><rect width="18" height="18" x="3" y="4" rx="2"></rect><path d="M3 10h18"></path><path d="M8 14h.01"></path><path d="M12 14h.01"></path><path d="M16 14h.01"></path><path d="M8 18h.01"></path><path d="M12 18h.01"></path><path d="M16 18h.01"></path></svg></span>
                    <span class="font-bold">
                        {{ product.meeting_amount }} {{ product.meeting_amount > 1 ? label('site.product.meetings') : label('site.product.meeting') }}
                    </span>
                </li>
                <li class="flex mt-2 text-left" v-show="product.target_audience !== ''">
                    <span class="mr-4"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#cc006c" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="h-6 w-6 lucide lucide-user-check"><path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><polyline points="16 11 18 13 22 9"></polyline></svg></span>
                    <span>{{ product.target_audience }}</span>
                </li>
                <li class="flex mt-2 text-left" v-show="!isProduct">
                    <span class="mr-4"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#cc006c" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="h-6 w-6 lucide lucide-calendar-clock"><path d="M21 7.5V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h3.5"></path><path d="M16 2v4"></path><path d="M8 2v4"></path><path d="M3 10h5"></path><path d="M17.5 17.5 16 16.3V14"></path><circle cx="16" cy="16" r="6"></circle></svg></span>
                    <span v-if="firstVariant?.formatted_start_date">{{ firstVariant?.formatted_start_date }}</span>
                    <span v-else-if="product.type === ProductType.SelfStudy && product.variants_count >= 1">{{ label('site.product.e-learning.date') }}</span>
                    <span v-else-if="product.type === ProductType.SelfStudy">E-learning</span>
                    <span v-else>{{ label('site.product.unknown_date')}}</span>

                    <a :href="'/' + product.slug + '#datums'" v-if="product.variants_count >= 1 && !isProduct">
                        <span class="py-1 px-2 rounded-xl lg:rounded-full bg-gray-100 text-xs font-semibold text-black ml-0 lg:ml-2 flex items-center">+ {{ product.variants_count - 1}} data</span>
                    </a>
                </li>
                <li class="flex mt-2" v-show="product.points > 0 && !isProduct">
                    <span class="mr-4"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#cc006c" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="h-6 w-6 lucide lucide-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"/></svg></span>
                    <span>{{ product.points ?? 'Onbekend aantal' }} {{ label('site.product.points') }}</span>
                </li>
            </ul>
        </div>
        <a :href="'/' + product.slug + '/'" class="mt-auto text-center flex justify-center py-4 px-8 text-white font-bold bg-primary hover:bg-primary-600 rounded-full focus:bg-primary-600 focus:outline-none">
            <span v-if="isProduct">{{ label('site.product.more_information_product') }}</span>
            <span v-else>{{ label('site.product.more_information') }}</span>
        </a>
    </div>
</template>
