<script setup lang="ts">

import {useFilterStore} from "../../stores/filter";
import {label} from "../../mixins/translations";
import {computed} from "vue";

const filterStore = useFilterStore();
const searchQuery = computed(() => {
    return filterStore.getFilter('search');
});

const updateFilter = (input) => {
    filterStore.setFilterValue('search', input.target.value, true);
}
</script>

<template>
    <div class="relative w-full max-w-xs xl:max-w-lg 2xl:max-w-2xl bg-white hidden xl:flex items-center border border-gray-200 rounded-lg focus-within:shadow-lg">
        <input
            class="appearance-none outline-none focus:border-secondary-300 border-0 focus:ring-0 bg-transparent font-normal text-sm pl-4 w-9/12"
            type="text" v-model="searchQuery" :placeholder="label('site.search.placeholder')" @keyup="updateFilter" >
        <div class="ml-auto px-4 text-muted-foreground">
            <svg xmlns="http://www.w3.org/2000/svg" class="size-5" fill="none"
                 viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
            </svg>
        </div>
    </div>
</template>
