<script setup lang="ts">
import {computed, ref, watch} from "vue";
import 'nouislider/dist/nouislider.css';
import {useFilterStore} from "../../stores/filter";
import {label} from "../../mixins/translations";

const filterStore = useFilterStore();

const currentDate = computed(() => {
    return filterStore.getFilter('date');
});
const updateFilter = (ele) => {
    filterStore.setFilterValue('date', ele.target.value, true);
}
</script>

<template>
    <div class="w-full lg:pb-5">
        <h2 class="py-3">{{ label('site.product.start_date') }}</h2>
        <div class="w-full lg:mb-5">
            <input type="date" id="date" @change="updateFilter" :value="currentDate" class="bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-black block w-full p-2.5" />
        </div>
    </div>
</template>
