import {defineStore} from 'pinia';
import {filter as _filter} from 'lodash';
import {forEach as _forEach} from 'lodash';
import {pull as _pull} from 'lodash';
import mitt, {Emitter} from "mitt";
import {ref, Ref} from "vue";
import {TagCategoryResponse} from "../../responses/TagCategoryResponse";

type Events = {
    updatedFilter: void
    deletedFilters: void
}

export const useFilterStore = defineStore('filter', () => {
    const events: Emitter<Events> = mitt<Events>();
    const filter: Ref<String> = ref('');
    const filters: Ref<Object> = ref({});
    const tagCategories: Ref<Array<TagCategoryResponse>> = ref({});
    const defaultFilters: Ref<Array<String>> = ref([]);
    const checkedCategories: Ref<Array<Number>> = ref([]);

    const toggleCategory = (categoryId) => { //used to remember toggled categories on mobile
        let index = checkedCategories.value.indexOf(categoryId);
        if(index > -1) checkedCategories.value.splice(index, 1);
        else checkedCategories.value.push(categoryId);
    }
    const setFilter = (filter, value) => {
        filters.value[filter] = _filter(value, (item) => undefined !== item);
    }
    const setFilterValue = (name, value, emit) => {
        filters.value[name] = value;

        if(emit) {
            events.emit('updatedFilter');
        }
    }
    const getFilter = (name) => {
        return filters.value[name];
    }

    const getFilterCount = () => {
        return Object.keys(filters.value).length;
    }

    const hasFilter = (name) => {
        return filters.value.hasOwnProperty(name);
    }

    const hasFilterWithValue = (name, value) => {
        if (hasFilter(name)) {
            let filterValue = getFilter(name);

            if (Array.isArray(filterValue)) {
                return filterValue.includes(value);
            }

            return filterValue === value;
        }

        return false;
    }

    const removeFilterWithValue = (name, value) => {
        if (hasFilterWithValue(name, value)) {
            let filter = getFilter(name);

            if (Array.isArray(filter)) {
                filter = _filter(filter, (item) => value !== item);
            }

            if (filter.length >= 1) {
                setFilter(name, filter)
            } else {
                removeFilter(name);
            }
        }
    }

    const addOrRemoveFilter = (name, value) => {
        if(hasFilterWithValue(name, value)){
            removeFilterWithValue(name, value)
        }
        else {
            addFilter(name, value);
        }

        events.emit('updatedFilter');
    }

    const removeFilter = (name) => {
        if (hasFilter(name)) {
            delete filters.value[name];
        }
    }

    const resetFilters = () => {
        for(let filterToDelete in filters.value){
            delete filters.value[filterToDelete];
        }

        events.emit('updatedFilter');
        events.emit('deletedFilters');

        window.scroll({
            top: document.getElementById('productOverview').offsetTop,
            left: 0,
            behavior: 'smooth'
        });
    }

    const addFilter = (name, value) => {
        if (!(typeof value === "string")) {
            console.error(`Only strings allowed to add as filter (${name})`);
            return this;
        }

        let currentValue = filters.value[name];

        if (Array.isArray(currentValue)) {
            if (!currentValue.hasOwnProperty(value)) {
                currentValue.push(value);
            }
        } else {
            currentValue = [currentValue, value];
        }

        setFilter(name, currentValue);
    }

    const toUrlString = (page = null, additionalFilters = null, rawFilter = false, pushToHistory = false) => {
        let params = filters.value;
        _forEach(defaultFilters.value, (value) => {
            removeFilterWithValue('tag', value);
        });
        let url = '';

        if (additionalFilters && typeof additionalFilters === 'object') {
            params = {...params, ...additionalFilters};
        }

        if (!rawFilter) {
            params = Object.keys(params)
                .reduce((obj, key) => {
                    obj[key] = params[key];
                    return obj;
                }, {});
        }

        url += Object.keys(params)
            .filter(function (key) {
                return (params[key] !== null) && (params[key] !== '') //&& !keyHasDefaultValue(key, params[key]);
            })
            .map((key) => {
                let string = '';
                if (Array.isArray(params[key])) {
                    for (let i = 0; i < params[key].length; i++) {
                        let value = params[key][i];
                        string += encodeURIComponent(key) + '[]=' + value;

                        if (params[key].length !== i + 1) {
                            string += '&';
                        }
                    }
                } else if(!(params[key] === null) && params[key].length > 0) {
                    string += encodeURIComponent(key) + '=' + params[key];
                }
                return string;
            })
            .join('&');

        if (url !== '' && !url.includes('?')) {
            url = `?` + url;
        }

        if (page !== null && page > 1) {
            url += (url.includes('?') ? '&' : '?') + `page=${page}`;
        }
        if (pushToHistory) {
            if (url.length > 0) {
                history.pushState({}, '', url);
            } else {
                // Empties the url query params:
                history.pushState({}, '', location.pathname);
            }
        }

        return url;
    }

    const toRawUrlString = (page = null, additionalFilters = null) => {
        return toUrlString(page, additionalFilters, true);
    }

    const setInitialFilters = (filters) => {
        for (let key in filters) {
            let filter = filters[key];
            if (Array.isArray(filter.value)) {
                for (let key in filter.value) {
                    let value = filter.value[key];
                    if (value !== undefined) {
                        addFilter(filter.name, value);
                    }
                }
            } else {
                if (filter.value !== undefined) {
                    if(filter.multi) {
                        addFilter(filter.name, filter.value);
                    } else {
                        setFilterValue(filter.name, filter.value, false);
                    }
                }
            }
        }
    }

    const setDefaultFilters = (filters) => {
        defaultFilters.value = filters;
    }

    return {
        events,
        filter,
        filters,
        defaultFilters,
        tagCategories,
        checkedCategories,
        setFilter,
        setFilterValue,
        getFilterCount,
        getFilter,
        hasFilter,
        hasFilterWithValue,
        removeFilterWithValue,
        addOrRemoveFilter,
        removeFilter,
        resetFilters,
        addFilter,
        toUrlString,
        toRawUrlString,
        setInitialFilters,
        toggleCategory,
        setDefaultFilters
    }
});
