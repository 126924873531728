<script setup lang="ts">

import {AddressResponse} from "../../../responses/AddressResponse";
import {label} from "../../mixins/translations";
import {ref, Ref} from "vue";
import {DialogTrigger} from "radix-vue";
import AddressInput from "./AddressInput.vue";
import {useAddressStore} from "../../stores/address";
import Button from "../../../backend/ui/Button.vue";
import Input from "../../../backend/ui/Input.vue";



interface Props {
    modelValue?: AddressResponse,
    options?: AddressResponse[]
    countries: { [key: string]: string },
    type?: string,
    names?: Array<string>
}

const props = withDefaults(defineProps<Props>(),{
    options: [],
    countries: [],
    type: 'delivery',
    names: {
        id: "id",
        company: "company",
        salutation: "salutation",
        first_name: "first_name",
        insertion: "insertion",
        last_name: "last_name",
        postal: "postal",
        house_number: "house_number",
        house_number_addition: "house_number_addition",
        street: "street",
        city: "city",
        country: "country",
        address_line_1: 'address_line_1',
        address_line_2: 'address_line_2',
        type: 'type',
    },
})


const addressList: Ref<Array<AddressResponse>> = ref(props.options)
const addressStore = useAddressStore()

const createAddress: Ref<AddressResponse|object> = ref({ type: props.type})
const createErrors = ref({});
const openCreate = ref(false);

const selected: Ref<AddressResponse>|null = ref(props.modelValue)
const openSelect: Ref<boolean> = ref(false);

const emit = defineEmits<{
    (e: 'update:modelValue', value: object | null): void;
}>();

const setSelected = (newValue) => {
    selected.value = newValue;
    openSelect.value = false;
    emit('update:modelValue', selected.value);
}


const updateAddress = async (address, newValue) => {
    createAddress.value = newValue;
}

const saveAddress = async (address) => {
    let response = await addressStore.createAddress(address);
    if(response.message === 'success' && response.address) {
        addressList.value.push(response.address);
        setSelected(response.address)
        createAddress.value = { type: props.type};
        openCreate.value = false;

    } else {
        createErrors.value = response.errors;
    }
}

const removeFromList = (id) => {
    addressList.value = addressList.value.filter(function(item) {
        return item.id !== id;
    });

    if(selected.value.id == id) {
        if(addressList.value.length) {
            selected.value = addressList.value[0]
        } else {
            selected.value = null;
        }
    }
}

</script>

<template>
    <div>
        <AddressItem v-if="selected" class="cursor-pointer" :current="selected" :countries="countries" :show-delete="false" :show-edit="false" @click="openSelect = true"></AddressItem>
        <button class="border font-semibold rounded-md items-center justify-center w-full p-5 primary hover:bg-gray-300 mt-6" v-else @click.prevent="openSelect = true">
            {{ label('site.address.select') }}
        </button>

        <div v-if="selected">
            <input type="hidden" :name="names.id" v-model="selected.id"/>
            <input type="hidden" :name="names.type" v-model="selected.type"/>
            <input type="hidden" :name="names.company" v-model="selected.company"/>
            <input type="hidden" :name="names.salutation" v-model="selected.salutation"/>
            <input type="hidden" :name="names.first_name" v-model="selected.first_name"/>
            <input type="hidden" :name="names.insertion" v-model="selected.insertion"/>
            <input type="hidden" :name="names.last_name" v-model="selected.last_name"/>
            <input type="hidden" :name="names.street" v-model="selected.street"/>
            <input type="hidden" :name="names.address_line_1" v-model="selected.address_line_1"/>
            <input type="hidden" :name="names.address_line_2" v-model="selected.address_line_2"/>
            <input type="hidden" :name="names.house_number" v-model="selected.house_number"/>
            <input type="hidden" :name="names.house_number_addition" v-model="selected.house_number_addition"/>
            <input type="hidden" :name="names.postal" v-model="selected.postal"/>
            <input type="hidden" :name="names.city" v-model="selected.city"/>
            <input type="hidden" :name="names.country" v-model="selected.country"/>
        </div>
        <Modal v-model:open="openSelect">
            <template #trigger-wrapper>&nbsp;</template>
            <template #title>{{ label('site.address.select')}}</template>
            <div class="grid gap-2 grid-cols-1">
                <address-item class="cursor-pointer" @delete="(id) => removeFromList(id)" :countries="countries" @select="(val) => setSelected(val)" :current="item" v-for="item in addressList"></address-item>
            </div>
            <Modal v-model:open="openCreate">
                <template #trigger-wrapper>
                    <DialogTrigger class="flex items-center justify-center w-full p-5 border-[#D1D1D2] border-2 bg-[#ECECEC] hover:bg-gray-300 mt-6">
                        {{ label('site.button.create') }}
                    </DialogTrigger>
                </template>
                <template #title>{{ label('site.address.edit')}}</template>
                <address-input class="min-h-12" :type="type" :errors="createErrors" :model-value="createAddress" @update:model-value="value => updateAddress(createAddress, value)" :countries="countries" :show-inputs="true" :show-address-inputs="true" :hide-preview="true">
                    <template #buttons>
                        <button class="bg-primary-500 hover:bg-primary-700 text-primary-foreground px-5 pointer rounded-xl flex items-center justify-center h-12 " @click.prevent="saveAddress(createAddress)">
                            {{ label('site.button.create') }}
                        </button>
                    </template>
                </address-input>
            </Modal>
        </Modal>
    </div>
</template>
