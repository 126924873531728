<script setup lang="ts">
import {ref} from "vue";
import Product from "./Product.vue";
import ProductQuickbuy from "./ProductQuickbuy.vue";
import {ProductResponse} from "../../../responses/ProductResponse";

interface Props{
    product: ProductResponse
}
const props = defineProps<Props>();
const scrollComponent = ref(null);
let quickBuy = ref(null);
const selectQuickbuy = (product) => {
    quickBuy.value = product;
}
</script>

<template>
    <div>
        <div class="grid" :class="'lg:grid-cols-4 md:grid-cols-3 grid-cols-1 gap-10'" ref="scrollComponent" v-if="props.product.related_products.length > 0">
            <Product v-for="relatedProduct in props.product.related_products" :product="relatedProduct" layout="grid" @quickBuy="selectQuickbuy">
            </Product>
            <ProductQuickbuy v-if="quickBuy" :product="quickBuy" @close="quickBuy = null"></ProductQuickbuy>
        </div>
    </div>
</template>

