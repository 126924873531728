<script setup lang="ts">
import {onBeforeUnmount, onMounted, Ref, ref} from "vue";

interface Props {
    enabled: boolean|1|0
}


const props = withDefaults(defineProps<Props>(), {
    enabled: false
})

const currentElement: Ref<HTMLElement|null> = ref(null)

const preventClick = (event) => {
    event.preventDefault()
    event.stopImmediatePropagation()
    event.stopPropagation()
    event.preventDefault()
    return false;
}

onMounted(() => {
    if (props.enabled) {
        for (const element: HTMLElement of currentElement.value.querySelectorAll('*')) {
            element.setAttribute('tabindex', '-1')
            element.classList.add('outline-none')
            element.addEventListener('click', preventClick, true)
        }
    }
})

onBeforeUnmount(() => {
    if (props.enabled) {
        for (const element: HTMLElement of currentElement.value.querySelectorAll('*')) {
            element.removeAttribute('tabindex')
            element.classList.remove('outline-none')
            element.removeEventListener('click', preventClick)
        }
    }
})

</script>

<template>
    <div class="relative" ref="currentElement" tabindex="-1">
        <slot/>
    </div>
</template>
