<script setup lang="ts">
import {useProductStore} from "../../stores/product";
import {useFilterStore} from "../../stores/filter.ts";
import ProductSidebar from "./ProductSidebar.vue";
import ProductList from "./ProductList.vue";
import {computed, nextTick, onMounted, ref, watch} from "vue";
import {FilterXIcon, LoaderCircleIcon} from "lucide-vue-next";
import {label} from "../../mixins/translations";
import {FilterResponse} from "../../../responses/FilterResponse";
import Pagination from "../Pagination.vue";

interface Props {
    sort?: String,
    page?: Number,
    filters: Array<FilterResponse>
    propertyFilters: Array<FilterResponse>
    initialFilters: Object
    defaultFilters: Object
    categorySlug?: String
}

const props = withDefaults(defineProps<Props>(), {
    sort: 'variants_start_date|asc',
    page: 1,
});
const productStore = useProductStore();
const filterStore = useFilterStore();
const loading = ref(true);
const sort = ref(props.sort);
const page = ref(props.page);
const layout = ref('list');
const showFilters = ref(false);

const products = computed(() => {
    return productStore.products.data;
})

onMounted(() => {
    filterStore.setDefaultFilters(props.defaultFilters);
    filterStore.setInitialFilters(props.initialFilters);
    productStore.setCategorySlug(props.categorySlug);
    productStore.setSort(sort.value);
    productStore.setPage(page.value);
    getProducts();

    filterStore.tagCategories = props.tagCategories;
});

watch(
    () => sort.value,
    (newValue, oldValue) => {
        if (oldValue !== newValue) {
            productStore.setSort(newValue);
            getProducts();
        }
    }
)

const loadNextPage = (page) => {
    productStore.setPage(page);
    getProducts();

    window.scroll({
        top: document.getElementById('productOverview').offsetTop,
        left: 0,
        behavior: 'smooth'
    });
}

const getProducts = () => {
    // loading.value = true;
    productStore.getProducts()
        .then(() => {
            loading.value = false;
        }).catch(console.error);
}

filterStore.events.on('updatedFilter', () => {
    nextTick(() => {
        productStore.setPage(1);
        getProducts();
    });
})
</script>
<template>
    <div class="container lg:flex flex-col-reverse py-4 mx-auto gap-10 lg:gap-20 lg:flex-row"
         id="productOverview">
        <div class="w-full lg:hidden px-6">
            <h1 class="lg:mt-8">
                <slot name="title"></slot>
            </h1>
            <p class="my-4 text-base leading-8 text-gray-900">
                <slot name="description_top"></slot>
            </p>
        </div>
        <div class="flex flex-col w-full lg:w-3/12 ">
            <div class="w-full px-6">
                <button @click.prevent="showFilters = true;" v-show="!showFilters"
                        class="lg:hidden w-full flex justify-center px-6 py-3 text-white font-bold bg-secondary-950 text-white hover:bg-secondary-900 rounded-full">
                    <div class="flex items-center justify-center px-6">
                        Toon filters
                    </div>
                </button>
            </div>
            <button @click.prevent="showFilters = false;" v-show="showFilters"
                    class="sticky top-20 left-0 z-10 lg:hidden sm:block w-full flex justify-center px-4 py-3 text-white font-bold bg-secondary-950 text-white hover:bg-secondary-900">
                <div class="flex items-center justify-center">
                    <FilterXIcon class="h-4 w-4"></FilterXIcon>
                    <div class="ml-2">
                        Toon resultaten (<span v-text="productStore.productCount"></span>)
                    </div>
                </div>
            </button>

            <div :class="{'block': showFilters, 'hidden': !showFilters}"
                 class="lg:flex w-full py-3 lg:p-12 bg-white px-6 lg:bg-gray-50 rounded-2xl sticky top-40 overflow-y-auto max-h-inherit lg:max-h-[80vh] "
                 style="scrollbar-width: none;">
                <ProductSidebar :filters="filters" :initial="initialFilters"
                                :property-filters="propertyFilters"></ProductSidebar>

                <div class="w-full lg:hidden">
                    <h2 class="py-3 flex items-center justify-between lg:border-b-0 border-b border-gray-200 !m-0">
                        Sorteer op
                    </h2>
                    <div class="grid relative">
                        <select id="sort" v-model="sort"
                                class="rounded bg-none bg-white row-start-1 col-start-1 appearance-none border border-gray-300 text-gray-900 text-sm focus:ring-black block w-full py-1.5 px-3">
                            <option value="variants_start_date|asc">{{
                                    label('site.product.sort_by.start_date_asc')
                                }}
                            </option>
                            <option value="variants_start_date|desc">{{
                                    label('site.product.sort_by.start_date_desc')
                                }}
                            </option>
                            <option value="price|asc">{{ label('site.product.sort_by.price_asc') }}</option>
                            <option value="price|desc">{{ label('site.product.sort_by.price_desc') }}</option>
                        </select>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                             stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                             class="absolute w-4 h-4 right-3 top-2.5 pointer-events-none row-start-1 col-start-1 lucide lucide-chevron-down">
                            <path d="m6 9 6 6 6-6"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>

        <div class="w-full lg:w-9/12 flex align-center justify-center px-6" v-if="loading">
            <LoaderCircleIcon class="h-20 w-20 animate-spin" stroke-width="1" stroke="gray"></LoaderCircleIcon>
        </div>
        <div class="flex flex-col w-full lg:w-9/12 px-6" v-else>
            <h1 class="lg:mt-8 hidden lg:block">
                <slot name="title"></slot>
            </h1>
            <p class="mt-4 text-base leading-8 text-gray-900 hidden lg:block">
                <slot name="description_top"></slot>
            </p>

            <div class="w-full mt-6 bg-gray-50 rounded-full px-6 py-3 flex justify-between hidden lg:inline-flex">
                <Pagination
                    v-model="productStore.products"
                    @loadNextPage="loadNextPage"
                />

                <div class="w-full lg:w-4/12 ml-4 lg:ml-0">
                    <div class="grid relative">
                        <select id="sort" v-model="sort"
                                class="bg-none bg-white row-start-1 col-start-1 appearance-none border border-gray-300 text-gray-900 text-sm rounded-r-full focus:ring-black block w-full py-1.5 px-3">
                            <option value="variants_start_date|asc">{{
                                    label('site.product.sort_by.start_date_asc')
                                }}
                            </option>
                            <option value="variants_start_date|desc">{{
                                    label('site.product.sort_by.start_date_desc')
                                }}
                            </option>
                            <option value="price|asc">{{ label('site.product.sort_by.price_asc') }}</option>
                            <option value="price|desc">{{ label('site.product.sort_by.price_desc') }}</option>
                        </select>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                             stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                             class="absolute w-4 h-4 right-3 top-2.5 pointer-events-none row-start-1 col-start-1 lucide lucide-chevron-down">
                            <path d="m6 9 6 6 6-6"/>
                        </svg>
                    </div>
                </div>
            </div>

            <ProductList :show-filters="showFilters"></ProductList>

            <div class="w-full mt-6 bg-gray-50 rounded-full px-6 py-3 flex justify-between">
                <Pagination
                    class="w-full"
                    v-model="productStore.products"
                    @loadNextPage="loadNextPage"
                />
            </div>

            <div class="w-full">
                <p class="mt-8 text-base leading-8 text-gray-900">
                    <slot name="description_bottom"></slot>
                </p>
            </div>
        </div>
    </div>
</template>
