<script setup lang="ts">

import {computed, onMounted, ref, Ref} from "vue";

interface Video {
    videoId: string|null
    source: string,
    autoplay?: boolean
}

const props = withDefaults(defineProps<Video>(), {
    autoplay: false
})

const loaded: Ref<Boolean> = ref(false);
const triggerAutoplay: Ref<Boolean> = ref(props.autoplay);

onMounted(() => {
  if (props.source !== 'vimeo' && !props.vertical) {
    loaded.value = true;
  }

  if(props.source === 'vimeo') {
      triggerAutoplay.value = true;
  }
})

const url = computed(() => {
    return (props.source === 'vimeo' ? 'https://player.vimeo.com/video/' : 'https://www.youtube.com/embed/') + props.videoId;
})

</script>

<template>
    <div class="videoFrame cursor-pointer">
        <div v-if="source === 'vimeo' && !loaded"
             class="absolute top-0 left-0 w-full"
             :class="{
                'withVideoIcon': !loaded
             }"
             @click="loaded = true"
        >
            <img
                class="w-full pt-0.5 thumbnail"
                width="100%"
                height="100%"
                :srcset="`
                    https://vumbnail.com/${videoId}.jpg 640w,
                    https://vumbnail.com/${videoId}_large.jpg 640w,
                    https://vumbnail.com/${videoId}_medium.jpg 200w,
                    https://vumbnail.com/${videoId}_small.jpg 100w
                `"
                sizes="(max-width: 640px) 100vw, 640px"
                :src="`https://vumbnail.com/${videoId}.jpg`"
                alt="Vimeo Thumbnail"
                loading="eager"
            />
        </div>

        <iframe v-if="loaded"
                :src="`${url}?autoplay=${(triggerAutoplay ? '1' : '0')}`"
                title="Video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                loading="lazy"
                allowfullscreen
        />
    </div>
</template>
<style scoped>
.withVideoIcon {
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="size-6"><path stroke-linecap="round" stroke-linejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" /></svg>') no-repeat center center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: 60px!important;
  }

  &:hover {
    &:before {
      //background: url("/images/icon-play-hover.png") no-repeat center center;
      background-size: 60px!important;
    }
  }
}
.videoFrame{
    width: 100%;
    padding-top: 56.66%;
    position: relative;
}
.videoFrame iframe{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
</style>
