import {defineStore} from 'pinia';
import {computed, nextTick, ref, Ref} from "vue";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import {api} from "../scripts/api";
import {PageResponse} from "../../responses/PageResponse";
import {ProductResponse} from "../../responses/ProductResponse";
import {ArticleResponse} from "../../responses/ArticleResponse";


interface SearchResultGroup<T> {
    count: number,
    items: T[]
}

interface SearchResult {
    pages?: SearchResultGroup<PageResponse>
    products?: SearchResultGroup<ProductResponse>
    articles?: SearchResultGroup<ArticleResponse>
}

export const useSearchStore = defineStore('store', () => {
    const results: Ref<SearchResult> = ref({});
    const showMobile: Ref<boolean> = ref(false);

    const totalResults = computed(() => {
        let count = 0;
        for (const [key, value] of Object.entries(results.value)) {
            count += value.count;
        }
        return count;
    })

    const abortController: Ref<AbortController | null> = ref(null)


    const getSearchResults = (query: string, type: string): Promise<SearchResult> => {
        return new Promise(async (resolve, reject): Promise<SearchResult> => {
            // When already request going cancel it
            if (abortController.value != null) {
                abortController.value.abort();
            }

            // When query two or smaller resolve as not found
            if (query.length <= 1) {
                results.value = {}
                resolve({})
                return;
            }

            abortController.value = new AbortController()

            try {
                const response: AxiosResponse<SearchResult> = await api().post(
                    `/site/search/`,
                    {
                        query: query,
                        type: type
                    },
                    {signal: abortController.value.signal} as AxiosRequestConfig,
                )

                results.value = response.data;
                resolve(response.data);
            } catch (e) {
                console.error(e);
                reject(e)
            }
        });
    }

    const toggleSearch = () => {
        showMobile.value = !showMobile.value;
    }


    return {
        results,
        totalResults,
        showMobile,
        getSearchResults,
        toggleSearch,
    }
});
