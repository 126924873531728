export function setRecaptchaKey(key): boolean {
    let head = document.getElementsByTagName('head')[0];
    let script = document.createElement('script');
    script.type = 'text/javascript';

    script.src = `https://www.google.com/recaptcha/api.js?render=${key}`;
    head.appendChild(script);

    return true;
}
