<script setup lang="ts">
import {computed, ref} from "vue";
import Product from "./Product.vue";
import {label} from "../../mixins/translations.js";
import {useProductStore} from "../../stores/product";

interface Props{
    showFilters: Boolean
}
const productStore = useProductStore();
const props = defineProps<Props>();
const scrollComponent = ref(null);

const products = computed(() => {
    return productStore.products.data ?? [];
})

</script>

<template>
    <div class="w-full lg:p-0">
        <div class="w-full grid grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-0" ref="scrollComponent" v-if="products.length > 0">
            <Product v-for="product in products" :product="product">
            </Product>
        </div>
        <div class="w-full bg-secondary-50 p-8 text-center rounded-xl" v-else>
            {{ label('site.product.no_products_found') }}
        </div>
    </div>
</template>

