<script setup lang="ts">
import Modal from "../Modal.vue";
import {onMounted, Ref, ref} from "vue";
import CustomNumberInput from "../CustomNumberInput.vue";
import {find, first} from "lodash";
import {useCartStore} from "../../stores/cart";
import {ProductResponse} from "../../../responses/ProductResponse";
import {label} from "../../mixins/translations.js";

interface Props{
    product: ProductResponse
}
const props = defineProps<Props>();
const currentPrice = ref({}); //todo price response???
const loading: Ref<Boolean> = ref(false);
const amount: Ref<Number> = ref(1);
const cartStore = useCartStore();

const emit = defineEmits<{
    (e: 'close'): void;
}>();
const addToCart = () => {
    loading.value = true;
    cartStore.addItem({
        amount: amount.value,
        variant_id: cartStore.addToCartSelectedVariantModal.id
    }).then(response => {
        // window.trackAddToCartGoogle(this.product, this.amount, 'add_to_cart');
        updateAmount(1);
        loading.value = false;
        emit('close');
    });
}
const updateAmount = (newAmount) => {
    amount.value = newAmount;
    updatePrice();
}
const updateOpen = (open) => {
    if(!open){
        emit('close');
    }
};
const updatePrice = () => {
    currentPrice.value = find(cartStore.addToCartSelectedVariantModal.prices, function(price){
        return price.quantity <= amount.value;
    });
}

onMounted(() => {
    cartStore.setAddToCartSelectedVariantModal(first(props.product.variants));
    updateAmount(1);
});
</script>

<template>
    <Modal @updateOpen="updateOpen" :open="product != null">
        <template #trigger-wrapper>&nbsp;</template>
        <template #title>
            <div class="w-full">
                <div class="text-xl w-full text-gray-700 font-bold">{{ product.name }}</div>
                <div class="flex flex-col gap-4">
                    <div class="flex gap-4">
                        <div class="flex-1">
                            <p class="text-base font-semibold leading-relaxed">{{ label('site.product.select_variant') }}</p>
                            <select v-model="cartStore.addToCartSelectedVariantModal" @change="updatePrice" class="w-full text-sm border-secondary-200 rounded-xl py-2 px-3">
                                <option :value="variant" v-for="variant in product.variants">
                                    {{ variant.name }}
                                </option>
                            </select>
                        </div>
                        <div>
                            <p class="text-base font-semibold leading-relaxed">{{ label('site.product.quantity') }}</p>
                            <CustomNumberInput @input="updateAmount" :value="amount"></CustomNumberInput>
                        </div>
                    </div>
                </div>
                <div class="flex">
                    <p class="font-bold text-xl text-foreground" v-if="currentPrice">{{ $root.formatPrice(currentPrice.price_incl) }}</p>
                </div>
            </div>
        </template>
        <template #description>
            <div class="flex mt-4 gap-x-4 text-center">
                <button @click.prevent="addToCart"
                        class="h-10 px-5 pointer rounded-xl flex items-center justify-center text-primary-foreground bg-primary hover:bg-primary-600 transition-all duration-500 z-20"
                        :class="{'loading': loading}">
                    <svg class="h-5 w-5 mr-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" >
                        <circle cx="8" cy="21" r="1"/>
                        <circle cx="19" cy="21" r="1"/>
                        <path d="M2.05 2.05h2l2.66 12.42a2 2 0 0 0 2 1.58h9.78a2 2 0 0 0 1.95-1.57l1.65-7.43H5.12"/>
                    </svg>
                    <span>{{ label('site.product.add_to_cart') }}</span>
                </button>
                <button type="button"
                        class="md:block h-10 px-5 pointer rounded-xl font-medium flex items-center justify-center text-foreground bg-secondary-100 hover:bg-secondary-200 transition-all duration-500"
                        @click="emit('close')">
                    {{ label('site.cart.cancel') }}
                </button>
            </div>
        </template>
    </Modal>
</template>
