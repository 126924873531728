<script setup lang="ts">
import {ref} from "vue";
import {CheckoutStep, useCheckoutStore} from "../../../stores/checkout";
import Error from "../../Error.vue";
import {Loader2Icon} from "lucide-vue-next";
import {useCartStore} from "../../../stores/cart";
import {label} from "../../../mixins/translations";
import {capitalize} from "lodash";
import CartItemDates from "../CartItemDates.vue";
import CartItemLocation from "../CartItemLocation.vue";

interface Props{

}
const props = defineProps<Props>();
const checkoutStore = useCheckoutStore();
const cartStore = useCartStore();
const validationErrors = ref([]);
const submitForm = () => {
    validationErrors.value = [];
    return checkoutStore.updateParticipantInformation().then(response => {
        if(response.hasOwnProperty('route')){
            window.location = response.route;
        }
    }).catch(error => {
        validationErrors.value = error;
    });
}

const errorKey = (cartItemId, index, field) => {
    return 'participants' + '.' + cartItemId + '.' + index + '.' + field;
}
const [maxDate] = new Date().toISOString().split('T');
</script>

<template>
    <div class="container max-w-[800px] px-6 py-4 mx-auto gap-10 lg:gap-20 lg:flex-row">
        <div class="w-full bg-gray-50 py-8 px-8 lg:px-16 lg:py-16 rounded-xl">
            <h1 class="border-b border-secondary-100 pb-4 flex justify-between align-center justify-center">{{ label('site.cart.participant_information') }}</h1>

            <div v-for="cartItem in cartStore.cartCourseItems" class="border-b border-secondary-200 pb-4">
                <h3 class="text-lg font-bold tracking-tight text-secondary-950 lg:text-xl mt-4">
                    {{ cartItem.model.item.product.name }}
                </h3>

                <CartItemDates :cart-item="cartItem"></CartItemDates>
                <CartItemLocation :cart-item="cartItem"></CartItemLocation>

                <div v-for="quantity in cartItem.model.quantity" class="mt-1">
                    <h4 class="text-base font-bold tracking-tight text-gray-900 lg:text-lg mt-4 mb-2">
                        {{ capitalize(label('site.cart.participant_information.participant')) }} {{ quantity }}
                    </h4>

                    <div class="flex flex-col lg:flex-row gap-5 w-full mt-3">
                        <div class="w-full lg:w-5/12">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900">{{ label('site.account.first_name') }} <span class="text-red-500">*</span></label>
                            <input type="text" v-model="checkoutStore.participantInformationForm[cartItem.model.id][quantity-1].first_name" id="first_name" class="bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-black block w-full p-2.5" placeholder="" required />
                            <Error v-if="validationErrors.hasOwnProperty(errorKey(cartItem.model.id, quantity-1, 'first_name'))" :error="validationErrors[errorKey(cartItem.model.id, quantity-1, 'first_name')][0]"></Error>
                        </div>
                        <div class="w-full lg:w-2/12">
                            <label for="insertion" class="block mb-2 text-sm font-medium text-gray-900">{{ label('site.account.insertion') }}</label>
                            <input type="text" v-model="checkoutStore.participantInformationForm[cartItem.model.id][quantity-1].insertion" id="insertion" class="bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-black block w-full p-2.5" placeholder="" />
                            <Error v-if="validationErrors.hasOwnProperty(errorKey(cartItem.model.id, quantity-1, 'insertion'))" :error="validationErrors[errorKey(cartItem.model.id, quantity-1, 'insertion')][0]"></Error>
                        </div>
                        <div class="w-full lg:w-5/12">
                            <label for="last_name" class="block mb-2 text-sm font-medium text-gray-900">{{ label('site.account.last_name') }} <span class="text-red-500">*</span></label>
                            <input type="text" v-model="checkoutStore.participantInformationForm[cartItem.model.id][quantity-1].last_name" id="last_name" class="bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-black block w-full p-2.5" placeholder="" required />
                            <Error v-if="validationErrors.hasOwnProperty(errorKey(cartItem.model.id, quantity-1, 'last_name'))" :error="validationErrors[errorKey(cartItem.model.id, quantity-1, 'last_name')][0]"></Error>
                        </div>
                    </div>
                    <div class="flex flex-col lg:flex-row gap-5 w-full mt-3 mb-3">
                        <div class="w-full lg:w-5/12">
                            <label for="phone" class="block mb-2 text-sm font-medium text-gray-900">{{ label('site.account.phone') }} <span class="text-red-500">*</span></label>
                            <input type="text" v-model="checkoutStore.participantInformationForm[cartItem.model.id][quantity-1].phone" id="phone" class="bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-black block w-full p-2.5" placeholder="" required />
                            <Error v-if="validationErrors.hasOwnProperty(errorKey(cartItem.model.id, quantity-1, 'phone'))" :error="validationErrors[errorKey(cartItem.model.id, quantity-1, 'phone')][0]"></Error>
                        </div>
                        <div class="w-full lg:w-4/12">
                            <label for="email" class="block mb-2 text-sm font-medium text-gray-900">{{ label('site.account.email') }} <span class="text-red-500">*</span></label>
                            <input type="text" v-model="checkoutStore.participantInformationForm[cartItem.model.id][quantity-1].email" id="email" class="bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-black block w-full p-2.5" placeholder="" required />
                            <Error v-if="validationErrors.hasOwnProperty(errorKey(cartItem.model.id, quantity-1, 'email'))" :error="validationErrors[errorKey(cartItem.model.id, quantity-1, 'email')][0]"></Error>
                        </div>
                        <div class="w-full lg:w-3/12">
                            <label for="date_of_birth" class="block mb-2 text-sm font-medium text-gray-900">{{ label('site.account.date_of_birth') }} <span class="text-red-500">*</span></label>
                            <input type="date" :max="maxDate" v-model="checkoutStore.participantInformationForm[cartItem.model.id][quantity-1].date_of_birth" id="date_of_birth" class="bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-black block w-full p-2.5" placeholder="" required />
                            <Error v-if="validationErrors.hasOwnProperty(errorKey(cartItem.model.id, quantity-1, 'date_of_birth'))" :error="validationErrors[errorKey(cartItem.model.id, quantity-1, 'date_of_birth')][0]"></Error>
                        </div>
                    </div>
                    <div class="flex flex-col lg:flex-row gap-5 w-full mt-3 mb-3">
                        <div class="w-full lg:w-6/12">
                            <label for="occupation" class="block mb-2 text-sm font-medium text-gray-900">{{ label('site.account.occupation') }} <span class="text-red-500">*</span></label>
                            <select v-model="checkoutStore.participantInformationForm[cartItem.model.id][quantity-1].occupation" id="occupation" class="bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-black block w-full p-2.5">
                                <option value="">Selecteer functie</option>
                                <option :value="occupation.erp_id" v-for="occupation in checkoutStore.participantOccupations" v-text="occupation.name"></option>
                            </select>
                            <Error v-if="validationErrors.hasOwnProperty(errorKey(cartItem.model.id, quantity-1, 'occupation'))" :error="validationErrors[errorKey(cartItem.model.id, quantity-1, 'occupation')][0]"></Error>
                        </div>
                        <div class="w-full lg:w-6/12">
                            <label for="occupation_register" class="block mb-2 text-sm font-medium text-gray-900">{{ label('site.account.occupation_register') }} <span class="text-red-500">*</span></label>
                            <select v-model="checkoutStore.participantInformationForm[cartItem.model.id][quantity-1].occupation_register" id="occupation_register" class="bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-black block w-full p-2.5">
                                <option value="">Selecteer register</option>
                                <option :value="occupation.erp_id" v-for="occupation in checkoutStore.participantOccupationGroups" v-text="occupation.name"></option>
                            </select>
                            <Error v-if="validationErrors.hasOwnProperty(errorKey(cartItem.model.id, quantity-1, 'occupation_register'))" :error="validationErrors[errorKey(cartItem.model.id, quantity-1, 'occupation_register')][0]"></Error>
                        </div>
                    </div>
                    <div class="flex flex-col lg:flex-row gap-5 w-full mt-3 mb-3">
                        <div class="w-full lg:w-4/12" v-show="checkoutStore.participantInformationForm[cartItem.model.id][quantity-1].occupation === checkoutStore.occupationOtherErpId">
                            <label for="occupation_other" class="block mb-2 text-sm font-medium text-gray-900">{{ label('site.account.occupation_other') }} <span class="text-red-500">*</span></label>
                            <input type="text" v-model="checkoutStore.participantInformationForm[cartItem.model.id][quantity-1].occupation_other" id="occupation_other" class="bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-black block w-full p-2.5" placeholder="" required />
                            <Error v-if="validationErrors.hasOwnProperty(errorKey(cartItem.model.id, quantity-1, 'occupation_other'))" :error="validationErrors[errorKey(cartItem.model.id, quantity-1, 'occupation_other')][0]"></Error>
                        </div>
                        <div class="w-full lg:w-4/12" v-show="checkoutStore.participantInformationForm[cartItem.model.id][quantity-1].occupation_register === checkoutStore.occupationGroupOtherErpId">
                            <label for="occupation_register_other" class="block mb-2 text-sm font-medium text-gray-900">{{ label('site.account.occupation_register_other') }} <span class="text-red-500">*</span></label>
                            <input type="text" v-model="checkoutStore.participantInformationForm[cartItem.model.id][quantity-1].occupation_register_other" id="occupation_register_other" class="bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-black block w-full p-2.5" placeholder="" required />
                            <Error v-if="validationErrors.hasOwnProperty(errorKey(cartItem.model.id, quantity-1, 'occupation_register_other'))" :error="validationErrors[errorKey(cartItem.model.id, quantity-1, 'occupation_register_other')][0]"></Error>
                        </div>
                        <div class="w-full lg:w-4/12" v-show="checkoutStore.participantInformationForm[cartItem.model.id][quantity-1].occupation_register !== checkoutStore.occupationGroupNoneErpId && checkoutStore.participantInformationForm[cartItem.model.id][quantity-1].occupation_register !== ''">
                            <label for="id_number" class="block mb-2 text-sm font-medium text-gray-900">{{ label('site.account.id_number') }}</label>
                            <input type="text" v-model="checkoutStore.participantInformationForm[cartItem.model.id][quantity-1].id_number" id="id_number" class="bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-black block w-full p-2.5" placeholder="" required />
                            <Error v-if="validationErrors.hasOwnProperty(errorKey(cartItem.model.id, quantity-1, 'id_number'))" :error="validationErrors[errorKey(cartItem.model.id, quantity-1, 'id_number')][0]"></Error>
                        </div>
                    </div>
                </div>
            </div>

            <div class="w-full flex flex-col lg:flex-row gap-4 justify-between mt-4 lg:mt-10">
                <button @click.prevent="checkoutStore.setStep(CheckoutStep.Invoice)" type="button" class="hidden lg:block w-full lg:w-auto flex justify-center px-16 py-4 font-bold text-gray-800 border border-gray-200 bg-gray-100 hover:border-gray-300 hover:bg-gray-200 rounded-full focus:bg-gray-200 focus:outline-none">
                    {{ label('site.cart.step_back') }}
                </button>
                <button :class="{'cursor-wait bg-opacity-50 hover:none': checkoutStore.loading, 'hover:bg-primary-600': !checkoutStore.loading}" @click.prevent="submitForm" :disabled="checkoutStore.loading" type="button" class="w-full lg:w-auto flex justify-center px-16 py-4 text-white font-bold bg-primary rounded-full focus:bg-primary-600 focus:outline-none">
                    <div class="justify-center items-center text-white/90" v-show="checkoutStore.loading">
                        <Loader2Icon stroke-width="3" class="w-5 h-5 animate-spin mr-2 mt-0.5"/>
                    </div>

                    {{ label('site.cart.participant_information.next_step') }}
                </button>
            </div>
        </div>
    </div>
</template>
