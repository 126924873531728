<script lang="ts" setup>

import {MenuResponse} from "../../responses/MenuResponse";
import {take, slice} from 'lodash';
import {ref} from "vue";

interface Props{
    menu: MenuResponse
}

const props = withDefaults(defineProps<Props>(), {

});
const activeMenu = ref(null);
const toggleMenu = (activeId) => {
    if(activeMenu.value === activeId){
        activeMenu.value = null;
    }
    else {
        activeMenu.value = activeId;
    }
}
</script>
<template>
    <div id="mainmenu" class="left-0 lg:right-0 lg:left-2 z-10 top-20 lg:top-16 fixed lg:absolute bg-background divide-y divide-secondary-100 rounded-0 lg:rounded-lg shadow-xl w-full lg:w-64 hidden overflow-auto">
        <ul class="text-sm text-gray-700" v-for="item in take(menu.items, 4)">
            <li>
                <a :href="item.page?.full_slug ?? item.external_url" :class="{'py-3': item.items_count === 0}" class="block pl-3 hover:text-secondary-950 border-b border-gray-200 text-base font-bold w-full flex items-center justify-between">
                    <span>{{ item.label }}</span>
                    <span :class="{'rotate-180': activeMenu === item.id}" class="py-4 px-4 border-l  border-r border-gray-200 bg-gray-100 flex items-center justify-center" v-if="item.items_count > 0" @click.prevent="toggleMenu(item.id)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="h-4 w-4 lucide lucide-chevron-down"><path d="m6 9 6 6 6-6"/></svg>
                    </span>
                </a>
                <ul class="bg-gray-50" v-if="item.items_count > 0" :class="{'block': activeMenu === item.id, 'hidden': activeMenu !== item.id}">
                    <li v-for="subItem in item.items">
                        <a :href="subItem.page?.full_slug ?? subItem.external_url" class="text-base block px-4 py-2 text-gray-700 hover:text-secondary-950">
                            {{ subItem.label }}
                        </a>
                    </li>
                </ul>
            </li>
        </ul>
        <div class="w-full bg-gray-100">
            <ul class="text-[15px] text-gray-700" v-for="item in slice(menu.items, 4)">
                <li>
                    <a :href="item.page?.full_slug ?? item.external_url" class="block border-b border-gray-200 px-4 py-2 text-gray-700 hover:text-secondary-950">
                        <span>{{ item.label }}</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<style scoped>

</style>
