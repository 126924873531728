import "./site/google.js";
import "./site/password.js";
import.meta.glob([
    '../images/**',
]);

import {createPinia} from "pinia";

import {useCartStore} from "./site/stores/cart";
import {useLabelStore} from "./site/stores/label";
import {createApp} from 'vue/dist/vue.esm-bundler.js';

import CartOverview from "./site/Components/Cart/CartOverview.vue";
import CartInformation from "./site/Components/Cart/CartInformation.vue";
import CartHeader from "./site/Components/Cart/CartHeader.vue";
import AddToCart from "./site/Components/Cart/AddToCart.vue";
import CartStatus from "./site/Components/Cart/Checkout/CartStatus.vue";
import ProductOverview from "./site/Components/Product/ProductOverview.vue";
import AddressInput from "./site/Components/Address/AddressInput.vue";
import AddressList from "./site/Components/Address/AddressList.vue";
import AddressItem from "./site/Components/Address/AddressItem.vue";

import Modal from "./site/Components/Modal.vue";
import AddressSelector from "./site/Components/Address/AddressSelector.vue";
import Images from "./site/Components/Product/Images.vue";
import ProductPriceDetail from "./site/Components/Product/ProductPriceDetail.vue";
import RelatedProducts from "./site/Components/Product/RelatedProducts.vue";
import AfterAdding from "./site/Components/Product/AfterAdding.vue";
import ProductHelper from "./site/Components/Product/ProductHelper.vue";
import Product from "./site/Components/Product/Product.vue";
import SearchBar from "./site/Components/Search/SearchBar.vue";
import ContactForm from "./site/Components/Form/ContactForm.vue";
import WaitinglistForm from "./site/Components/Form/WaitinglistForm.vue";
import Skeleton from "./site/Components/Skeleton.vue";
import PreventInteraction from "./site/Components/PreventInteraction.vue";
import {isIframe} from "./utils/iframe";
import NewsletterForm from "./site/Components/Form/NewsletterForm.vue";
import ProductCarousel from "./site/Components/Product/ProductCarousel.vue";
import ArticleCarousel from "./site/Components/Articles/ArticleCarousel.vue";
import ArticleItem from "./site/Components/Articles/ArticleItem.vue";
import MainMenu from "./site/Components/MainMenu.vue";
import VideoWrapper from "./site/Components/VideoWrapper.vue";
import {useSearchStore} from "./site/stores/search";

const pinia = createPinia();

const app = createApp({
    methods: {
        formatPrice: (amount, currency, hideZero) => {
            let currencySign = "€";
            // let currencySign = ""; //geen eurotekens
            if (currency === "GBP") currencySign = "£";
            if (currency === "USD") currencySign = "$";

            hideZero = !!hideZero;

            if (!amount) {
                amount = 0;
            }

            if (hideZero && amount === 0) {
                return "-";
            } else {
                return (
                    currencySign +
                    (parseFloat(amount) / 100).toLocaleString(
                        undefined, // leave undefined to use the browser's locale,
                        // or use a string like 'en-US' to override it.
                        {minimumFractionDigits: 2, maximumFractionDigits: 2},
                    )
                );
            }
        },
        toggleSearch() {
            const searchStore = useSearchStore();
            searchStore.toggleSearch();
        }
    },
    mounted() {
        const cartStore = useCartStore();
        cartStore.setUuid(window.cart_uuid);
        cartStore.cart_currency = window.cart_currency;
        // cartStore.cart_route = window.cart_route;
        cartStore.setDeliveryCountryCode(window.delivery_country);

        // Prevent loading cart when in row manager
        if (!isIframe()) {
            cartStore.loadCart();
        }

        // flash_session();
    },
    created() {
        const labelStore = useLabelStore();
        labelStore.setFromJsonString(window.labels);
    }
});

app.use(pinia);

app
.component('CartOverview', CartOverview)
.component('CartHeader', CartHeader)
.component('AddToCart', AddToCart)
.component('AddressInput', AddressInput)
.component('ProductOverview', ProductOverview)
.component('ProductImages', Images)
.component('AddressSelector', AddressSelector)
.component('AddressList', AddressList)
.component('AddressItem', AddressItem)
.component('Modal', Modal)
.component('Product', Product)
.component('ProductPriceDetail', ProductPriceDetail)
.component('RelatedProducts', RelatedProducts)
.component('AfterAdding', AfterAdding)
.component('CartInformation', CartInformation)
.component('ProductHelper', ProductHelper)
.component('SearchBar', SearchBar)
.component('ContactForm', ContactForm)
.component('NewsletterForm', NewsletterForm)
.component('WaitinglistForm', WaitinglistForm)
.component('CartStatus', CartStatus)
.component('Skeleton', Skeleton)
.component('PreventInteraction', PreventInteraction)
.component('ProductCarousel', ProductCarousel)
.component('ArticleItem', ArticleItem)
.component('ArticleCarousel', ArticleCarousel)
.component('VideoWrapper', VideoWrapper)
.component('MainMenu', MainMenu);

app.mount('#app');
