<script setup lang="ts">
import CustomNumberInput from '../CustomNumberInput.vue';
import {useCartStore} from "../../stores/cart";
import {CartCalculatorLineResponse} from "../../../responses/Site/CartCalculatorLineResponse";
import {label} from "../../mixins/translations";
import CartItemDates from "./CartItemDates.vue";
import CartItemLocation from "./CartItemLocation.vue";
import {trackAddToCartGoogle} from "../../google";
import {ProductType} from "../../../enums/ProductType";

interface Props {
    cartItem: CartCalculatorLineResponse,
}

const props = defineProps<Props>();
const cart = useCartStore();
const handleAmountChange = (amount) => {
    if(amount === 0){
        trackAddToCartGoogle(props.cartItem.model.item.product, amount, 'remove_from_cart');
    }
    cart.updateQuantity({
        quantity: amount,
        cart_item_id: props.cartItem.model.id,
    });
    sessionStorage.removeItem('checkout-step');
}
</script>
<template>
    <div>
        <!--ITEM-->
        <div class="flex flex-col lg:flex-row w-full border-b border-gray-200 pb-8">
            <div class="flex flex-col w-full lg:w-7/12">
                <div class="w-full flex flex-row justify-between gap-4">
                    <h2 class="flex flex-1 w-7/12 mt-2">
                        <a :href="'/' + cartItem.model.item.product.slug">
                            {{ cartItem.model.item.product.name }}
                        </a>
                    </h2>
                    <div class="gap-2 flex flex-none w-5/12 flex-row lg:hidden justify-end ">
                        <h2 class="mt-2">{{ $root.formatPrice(cartItem.price_without_discount_excl, cartItem.currency) }}</h2>
                        <div class="text-lg font-bold" v-if="cartItem.price_discount_excl > 0">{{ $root.formatPrice(cartItem.price_excl, cartItem.currency) }}</div>
                        <span class="flex !w-10 h-10 bg-red-50 rounded-full justify-center items-center hover:bg-red-100 hover:cursor-pointer" @click="handleAmountChange(0)">
                            <svg class="h-4 w-4 text-red-500 lucide lucide-trash-2" fill="none" height="24" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M3 6h18"/><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"/><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"/><line x1="10" x2="10" y1="11" y2="17"/><line x1="14" x2="14" y1="11" y2="17"/></svg>
                        </span>
                    </div>
                </div>

                <CartItemDates v-if="cartItem.model.item.product.type !== ProductType.Product" :cart-item="cartItem"></CartItemDates>
                <CartItemLocation v-if="cartItem.model.item.product.type !== ProductType.Product" :cart-item="cartItem"></CartItemLocation>
            </div>
            <div class="flex flex-col w-full lg:w-5/12 items-start lg:items-end">
                <div class="gap-5 hidden lg:flex justify-between lg:justify-end w-full">
                    <h2 class="mt-2">{{ $root.formatPrice(cartItem.price_without_discount_excl, cartItem.currency) }}</h2>
                    <span class="flex w-12 h-12 bg-red-50 rounded-full justify-center items-center hover:bg-red-100 hover:cursor-pointer" @click="handleAmountChange(0)">
                        <svg class="h-5 w-5 text-red-500 lucide lucide-trash-2" fill="none" height="24" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M3 6h18"/><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"/><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"/><line x1="10" x2="10" y1="11" y2="17"/><line x1="14" x2="14" y1="11" y2="17"/></svg>
                    </span>
                </div>
                <div class="flex  w-full gap-2 lg:gap-5 mt-5 lg:mt-10 items-center lg:items-end justify-end lg:items-center">
                    <p v-if="cartItem.model.item.product.type !== ProductType.Product">{{ label('site.cart.amount_of_participants') }}</p>
                    <p v-else>{{ label('site.cart.quantity') }}</p>
                    <CustomNumberInput :value="cartItem.model.quantity" @input="handleAmountChange"></CustomNumberInput>
                </div>
            </div>
        </div>
    </div>
</template>
