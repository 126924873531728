<script setup lang="ts">
import {
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogOverlay,
    DialogPortal,
    DialogRoot,
    DialogTitle,
    DialogTrigger,
} from 'radix-vue'
import { XIcon } from "lucide-vue-next";
import {ref, Ref} from "vue";

interface Props {
    widthClass?: string,
    triggerOpen?: boolean,
    triggerClass?: string
}

const emit = defineEmits<{
    (e: 'updateOpen', boolean): void;
}>();

const props = withDefaults(defineProps<Props>(), {
    widthClass: "max-w-[500px]",
    triggerClass: "w-full"
})

const open: Ref<Boolean> = ref(false);
if(props.triggerOpen) open.value = true;
</script>

<template>
    <DialogRoot v-model:open="open" @update:open="emit('updateOpen', open)">
        <slot name="trigger-wrapper">
            <DialogTrigger :class="triggerClass">
                <slot name="trigger"/>
            </DialogTrigger>
        </slot>
        <DialogPortal>
            <DialogOverlay class="bg-slate-300 opacity-80 data-[state=open]:animate-overlayShow fixed inset-0 z-50" />
            <DialogContent
                :class="widthClass"
                class="overflow-y-auto data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] lg:max-h-[90vh] lg:max-h-[75vh] w-[90vw] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none z-[100]"
            >
                <DialogTitle class="text-secondary m-0 text-[17px] font-semibold">
                    <slot name="title"></slot>
                </DialogTitle>
                <DialogDescription class="text-secondary mt-[10px] text-[15px] leading-normal">
                    <slot name="description"></slot>
                </DialogDescription>
                <slot></slot>
                <DialogClose
                    class="text-secondary-900 rounded-full hover:bg-secondary-50 absolute top-[10px] right-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center focus:outline-none"
                    aria-label="Close"
                >
                    <slot name="close">
                        <x-icon></x-icon>
                    </slot>
                </DialogClose>
            </DialogContent>
        </DialogPortal>
    </DialogRoot>
</template>
