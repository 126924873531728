<script setup lang="ts">
import {useCartStore} from "../../stores/cart";
import {label} from "../../mixins/translations.js";
import {ProductResponse} from "../../../responses/ProductResponse";
import {ProductVariantResponse} from "../../../responses/ProductVariantResponse";
import {trackAddToCartGoogle} from "../../google";
import {ProductType} from "../../../enums/ProductType";

interface Props {
    product: ProductResponse
    productVariant: ProductVariantResponse
}
const props = defineProps<Props>();

const cart = useCartStore();
const selectVariant = (variant) => {
    cart.setAddToCartSelectedVariant(variant);
}
const addToCart = () => {
    selectVariant(props.productVariant);
    cart.addItem({
        amount: 1,
        variant_id: cart.addToCartSelectedVariant.id
    });

    sessionStorage.removeItem('checkout-step');

    trackAddToCartGoogle(props.product, 1, 'add_to_cart');
}
</script>
<template>
    <button type="button" class="w-full lg:w-auto flex justify-center px-16 py-4 text-white font-bold bg-primary hover:bg-primary-600 rounded-full focus:bg-primary-600 focus:outline-none" @click="addToCart">
        <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-shopping-cart mr-6"><circle cx="8" cy="21" r="1"/><circle cx="19" cy="21" r="1"/><path d="M2.05 2.05h2l2.66 12.42a2 2 0 0 0 2 1.58h9.78a2 2 0 0 0 1.95-1.57l1.65-7.43H5.12"/></svg></span>
        <span v-if="product.type === ProductType.Product">{{ label('site.product.add_to_cart_service') }}</span>
        <span v-else>{{ label('site.product.add_to_cart') }}</span>
    </button>
</template>
