<script setup lang="ts">
import {ref} from "vue";
import {CheckoutStep, useCheckoutStore} from "../../../stores/checkout";
import Error from "../../Error.vue";
import {Loader2Icon} from "lucide-vue-next";
import {label} from "../../../mixins/translations";
import AddressInput from "../../Address/AddressInput.vue";
import {useCartStore} from "../../../stores/cart";

interface Props{

}
const props = defineProps<Props>();
const checkoutStore = useCheckoutStore();
const cartStore = useCartStore();
const validationErrors = ref({});
const submitForm = () => {
    validationErrors.value = {};
    return checkoutStore.updateDeliveryInformation().then(response => {
        checkoutStore.setStep(CheckoutStep.Invoice);
    }).catch(error => {
        validationErrors.value = error.errors;

        window.scroll({
            top: document.getElementById(Object.keys(error.errors)[0] + '_label').offsetTop, //scroll to first error
            left: 0,
            behavior: 'smooth'
        });
    });
}
const updateDelivery = (newAddressValue) => {
    Object.entries(newAddressValue).forEach(([key, value]) => {
        checkoutStore.deliveryInformationForm[key] = value;
    })
}
</script>

<template>
    <div class="container max-w-[800px] px-6 py-4 mx-auto gap-10 lg:gap-20 lg:flex-row">
        <div class="w-full bg-gray-50 py-8 px-8 lg:px-16 lg:py-16 rounded-xl" id="cart-delivery-information">
            <h2 class="pb-2 flex justify-between align-center justify-center" v-if="!checkoutStore.loggedIn">{{ label('site.cart.create_account') }}</h2>
            <div class="w-full mb-3" v-if="!checkoutStore.loggedIn">
                <div class="flex items-center mb-3">
                    <input id="create_account" value="1" type="checkbox" v-model="checkoutStore.deliveryInformationForm.create_account" class="w-5 h-5 bg-white border-gray-300 rounded focus:ring-2">
                    <label for="create_account" class="ms-2 text-sm font-medium text-gray-900">{{ label('site.cart.your_information.create_account') }}</label>
                </div>
            </div>
            <input id="create_account" value="0" type="hidden" v-model="checkoutStore.deliveryInformationForm.create_account" v-else>

            <div class="flex flex-col lg:flex-row gap-5 w-full mt-3" v-if="!checkoutStore.loggedIn && checkoutStore.deliveryInformationForm.create_account">
                <div class="w-full lg:w-6/12">
                    <label for="password" class="block mb-2 text-sm font-medium text-gray-900" id="password_label">{{ label('site.account.password') }} <span class="text-red-500">*</span></label>
                    <input type="password" id="password" v-model="checkoutStore.deliveryInformationForm.password" class="bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-black block w-full p-2.5" placeholder="" required />
                    <Error id="password-error" v-if="validationErrors.hasOwnProperty('password')" :error="validationErrors.password[0]"></Error>
                </div>
                <div class="w-full lg:w-6/12">
                    <label for="password_confirmation" class="block mb-2 text-sm font-medium text-gray-900" id="password_confirmation_label">{{ label('site.account.password_confirmation') }} <span class="text-red-500">*</span></label>
                    <input type="password" id="password_confirmation" v-model="checkoutStore.deliveryInformationForm.password_confirmation" class="bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-black block w-full p-2.5" placeholder="" required />
                    <Error v-if="validationErrors.hasOwnProperty('password_confirmation')" :error="validationErrors.password_confirmation[0]"></Error>
                </div>
            </div>
            <h2 class="pb-2 flex justify-between align-center justify-center mt-5" >{{ label('site.cart.your_information') }}</h2>
            <div class="w-full mt-1">
                <label class="block w-full mb-2 text-sm font-medium text-gray-900" id="payment_label" v-show="!cartStore.hasOnlyServices()">{{ label('site.cart.your_information.also_participant') }} <span class="text-red-500">*</span></label>
                <div class="flex flex-col lg:flex-row mt-3 mb-4 gap-3 lg:gap-5" v-if="!cartStore.hasOnlyServices()">
                    <div class="flex">
                        <input id="is_participant_yes" name="is_participant" value="1" type="radio" v-model="checkoutStore.deliveryInformationForm.is_participant" class="w-5 h-5 text-green-600 bg-white border-gray-300 rounded-full focus:ring-2">
                        <label for="is_participant_yes" class="ms-2 text-sm font-normal text-gray-900">Ja</label>
                    </div>
                    <div class="flex">
                        <input id="is_participant_no" name="is_participant" value="0" type="radio" v-model="checkoutStore.deliveryInformationForm.is_participant" class="w-5 h-5 text-green-600 bg-white border-gray-300 rounded-full focus:ring-2">
                        <label for="is_participant_no" class="ms-2 text-sm font-normal text-gray-900">Nee</label>
                    </div>
                </div>
                <div v-else>
                    <input id="is_participant" name="is_participant" value="0" type="hidden" v-model="checkoutStore.deliveryInformationForm.is_participant" class="w-5 h-5 bg-white border-gray-300 rounded focus:ring-2">
                </div>
                <Error v-if="validationErrors.hasOwnProperty('is_participant')" :error="validationErrors.is_participant[0]"></Error>
            </div>

            <address-input
                :countries="checkoutStore.countryOptions"
                :hide-preview="true"
                default-country="NL"
                :show-inputs="true"
                :model-value="checkoutStore.deliveryInformationForm"
                @update:model-value="updateDelivery($event)"
                :errors="validationErrors"
                :cart="true"
                :show-company="false"
            ></address-input>

            <div class="flex flex-col lg:flex-row gap-5 w-full">
                <div class="w-full lg:w-6/12">
                    <label for="email" class="block mb-2 text-sm font-medium text-gray-900" id="email_label">{{ label('site.account.email') }} <span class="text-red-500">*</span></label>
                    <input type="text" id="email" v-model="checkoutStore.deliveryInformationForm.email" class="bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-black block w-full p-2.5" placeholder="" required />
                    <Error v-if="validationErrors.hasOwnProperty('email')" :error="validationErrors.email[0]"></Error>
                </div>
                <div class="w-full lg:w-6/12">
                    <label for="phone" class="block mb-2 text-sm font-medium text-gray-900" id="phone_label">{{ label('site.account.phone') }} <span class="text-red-500">*</span></label>
                    <input type="text" id="phone" v-model="checkoutStore.deliveryInformationForm.phone" class="bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-black block w-full p-2.5" placeholder="" required />
                    <Error v-if="validationErrors.hasOwnProperty('phone')" :error="validationErrors.phone[0]"></Error>
                </div>
            </div>
            <div class="w-full mt-3">
                <label class="block w-full flex mb-2 text-sm font-medium text-gray-900" id="payment_label">{{ label('site.cart.your_information.who_pays') }} <span class="text-red-500">*</span></label>
                <div class="flex flex-col lg:flex-row mt-3 mb-4 gap-3 lg:gap-10">
                    <div class="flex">
                        <input id="payment_self" name="payment" value="0" type="radio" v-model="checkoutStore.deliveryInformationForm.payment" class="w-5 h-5 text-green-600 bg-white border-gray-300 rounded-full focus:ring-2">
                        <label for="payment_self" class="ms-2 text-sm font-normal text-gray-900">{{ label('site.cart.your_information.who_pays.self') }}</label>
                    </div>
                    <div class="flex">
                        <input id="payment_company" name="payment" value="1" type="radio" v-model="checkoutStore.deliveryInformationForm.payment" class="w-5 h-5 text-green-600 bg-white border-gray-300 rounded-full focus:ring-2">
                        <label for="payment_company" class="ms-2 text-sm font-normal text-gray-900">{{ label('site.cart.your_information.who_pays.company') }}</label>
                    </div>
                </div>
                <Error v-if="validationErrors.hasOwnProperty('payment')" :error="validationErrors.payment[0]"></Error>
            </div>
        </div>
        <div class="w-full flex flex-col lg:flex-row gap-4 justify-between mt-4 lg:mt-10">
            <a href="/cart" class="hidden lg:block w-full lg:w-auto flex justify-center px-16 py-4 font-bold text-gray-800 border border-gray-200 hover:border-gray-300 bg-gray-100 hover:bg-gray-200 rounded-full focus:bg-gray-200 focus:outline-none">
                {{ label('site.cart.back_to_cart') }}
            </a>
            <button :class="{'cursor-wait bg-opacity-50 hover:none': checkoutStore.loading, 'hover:bg-primary-600': !checkoutStore.loading}" @click.prevent="submitForm" :disabled="checkoutStore.loading" type="button" class="w-full lg:w-auto flex justify-center px-16 py-4 text-white font-bold bg-primary rounded-full focus:bg-primary-500 focus:outline-none">
                <div class="justify-center items-center text-white/90" v-show="checkoutStore.loading">
                    <Loader2Icon stroke-width="3" class="w-5 h-5 animate-spin mr-2 mt-0.5"/>
                </div>

                {{ label('site.cart.next_step') }}
            </button>
        </div>
    </div>
</template>
