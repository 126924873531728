<script setup lang="ts">

import {computed, type HTMLAttributes, type Ref, ref} from "vue";
import {label} from "../../mixins/translations";
import {useSearchStore} from "../../stores/search";
import {onClickOutside, useDebounceFn} from '@vueuse/core'
import {Loader2Icon} from "lucide-vue-next";
import {isCancel} from "axios";

interface Props {
    types?: Array<string>,
}

const props = withDefaults<Props, Props>(defineProps<Props>(), {
    types: []
});

const searchStore = useSearchStore();

const selectedOption: Ref<string> = ref('all');
const searchQuery: Ref<string> = ref('');
const openResults: Ref<boolean> = ref(false);

const isLoading: Ref<boolean> = ref(false);


const searchUrl = computed(() => {
    let url = '/search/' + searchQuery.value.toLowerCase() + '/';
    if (selectedOption.value !== 'all') {
        url = url + selectedOption.value.toLowerCase();
    }
    return url;
})

const navigate = () => {
    if (searchQuery.value.length) {
        window.location.href = searchUrl.value;
    }
}

const preview = useDebounceFn(async () => {

    isLoading.value = true

    searchStore.getSearchResults(searchQuery.value, selectedOption.value)
        .catch(e => {
            if (isCancel(e)) return;
            console.error(e);
        }).finally(() => {
        isLoading.value = false;
    })
    openResults.value = true;
}, 400)

const target = ref(null);
onClickOutside(target, event => openResults.value = false)
</script>

<template>
    <div
        class="w-full xl:max-w-lg w-full 2xl:max-w-2xl bg-white xl:flex items-center border border-gray-200 lg:rounded-lg focus-within:shadow-lg"
        :class="{
            'absolute top-[80px] left-0 flex': searchStore.showMobile,
            'hidden relative max-w-xs': !searchStore.showMobile
        }"
    >
        <input
            class="appearance-none outline-none focus:border-secondary-300 border-0 focus:ring-0 bg-transparent font-normal text-sm pl-4 z-10"
            :class="{'w-10/12': searchStore.showMobile, 'w-9/12': !searchStore.showMobile}"
            type="text" v-model="searchQuery" :placeholder="label('site.search.placeholder')" @keyup.enter="navigate"
            @keyup.prevent="preview">
        <div class="ml-auto px-4 text-muted-foreground">
            <svg v-if="!isLoading" xmlns="http://www.w3.org/2000/svg" class="size-5" fill="none"
                 @click.prevent="navigate"
                 viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
            </svg>
            <Loader2Icon v-else class="size-5 animate-spin"/>
        </div>

        <!--SEARCHRESULTS-->
        <div class="w-full absolute top-10 lg:top-12 bg-gray-50 shadow-xl rounded-xl overflow-hidden border border-gray-200  z-20" v-show="openResults"
             ref="target">
            <div v-for="(results, key) in searchStore.results">
                <div v-if="results.count">
                    <div v-if="key === 'articles'" class="w-full py-4 px-6 border-t border-gray-200 bg-gray-100">
                        <h3 class="w-full text-lg font-bold tracking-tight text-gray-900"
                            v-text="label('site.search.'+key)"></h3>
                        <div v-for="result in results.items">
                            <a :href="result.full_slug" class="w-full flex bg-white rounded-xl overflow-hidden mt-3">
                                <div class="w-1/4 h-auto bg-cover bg-center"
                                     :style="`background-image: url('${result.image}')`">
                                </div>
                                <div class="w-3/4 p-4">
                                    <h3 class="w-full text-md lg:text-lg font-bold tracking-tight text-gray-900" v-text="result.title"></h3>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div v-else-if="key === 'products'" class="w-full py-4 px-6 border-t border-gray-200 bg-gray-100">
                        <h3 class="w-full text-lg font-bold tracking-tight text-gray-900"
                            v-text="label('site.search.'+key)"></h3>
                        <div v-for="result in results.items">
                            <a :href="`/${result.slug}/`" class="w-full flex bg-white rounded-xl overflow-hidden mt-3">
                                <div class="w-1/4 h-auto bg-cover bg-center"
                                     :style="`background-image: url('${result.thumbnail.image_path}')`">

                                </div>
                                <div class="w-3/4 p-4">
                                    <h3 class="w-full text-md lg:text-lg font-bold tracking-tight text-gray-900" v-text="result.name"></h3>
                                </div>
                            </a>
                        </div>
                    </div>
                    <!--                    <div v-else-if="key === 'tags'" class="w-full flex py-4 px-6 border-t border-gray-200 gap-4">-->
                    <!--                        <a href="#" class="px-4 py-2 text-black text-sm font-normal bg-gray-100 hover:bg-white rounded-full focus:bg-primary-500 focus:outline-none">#praktijkondersteuners</a>-->
                    <!--                        <a href="#" class="px-4 py-2 text-black text-sm font-normal bg-gray-100 hover:bg-white rounded-full focus:bg-primary-500 focus:outline-none">#Kennis en wetenschap</a>-->
                    <!--                    </div>-->
                    <div v-else class="w-full py-4 px-6">
                        <h3 class="w-full text-md lg:text-lg font-bold tracking-tight text-gray-900"
                            v-text="label('site.search.'+key)"></h3>
                        <nav class="flex flex-col mt-2 space-y-2 text-sm text-gray-500">
                            <div v-for="result in results.items">
                                <a class="hover:text-black" :href="result.full_slug" v-text="result.title"></a>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
            <div class="w-full py-4 px-6 flex" v-if="searchStore.totalResults">
                <a :href="searchUrl"
                   class="px-6 py-3 w-full text-white text-center text-base font-bold bg-primary hover:bg-primary-600 rounded-full focus:bg-primary-500 focus:outline-none"
                   v-html="label('site.search.show-all-results', {'count': searchStore.totalResults})"></a>
            </div>
            <div v-else class="w-full py-4 px-6 flex text-sm text-gray-500">
                <div v-text="label('site.search.no-results')"></div>
            </div>
        </div>
    </div>
</template>
