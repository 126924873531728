<script setup lang="ts">

import Error from "../Error.vue";
import {label} from "../../mixins/translations";
import {onMounted, ref, Ref} from "vue";
import {api} from "../../scripts/api";
import Button from "../../../backend/ui/Button.vue";
import {setRecaptchaKey} from "../../scripts/recapcha";
import Input from "../../../backend/ui/Input.vue"
import {Loader2Icon} from "lucide-vue-next";
import {DebtorContactResponse} from "../../../responses/Site/DebtorContactResponse";
interface Props {
    route?: string,
    user?: DebtorContactResponse|null,
    recaptchaKey?: string,
    formName?: string
}
const props = withDefaults(defineProps<Props>(), {
    route: '/site/form/contact/',
    formName: 'contactform'
});

const message: Ref<string> = ref('');
const errors: Ref<object> = ref({});
const submitted: Ref<boolean> = ref(false);
const loading: Ref<boolean> = ref(false);

const form: Ref<object> = ref({
    recaptcha: ''
});


onMounted(() => {
    //Prefil with optional user data
    if(props.user) {
        form.value = {
            ...form.value,
            first_name: props.user.first_name,
            insertion: props.user.insertion,
            last_name: props.user.last_name,
            email: props.user.email,
        }
    }
    setRecaptchaKey(props.recaptchaKey);
});

const handleForm = () => {
    loading.value = true;
    grecaptcha.ready(function() {
        grecaptcha.execute(props.recaptchaKey, {action: props.formName}).then(function(token) {
           form.value.recaptcha = token;
            api().post(props.route ,form.value)
                .then((response) => {
                    submitted.value = true;
                    message.value = response.data.message;
                }).catch((error) => {
                    if(error.response) {
                        errors.value = error.response.data.errors ?? {};
                    }
                    if(error.response.data.message && error.response.status !== 422) {
                        message.value = error.response.data.message;
                    }
                    setRecaptchaKey(props.recaptchaKey);
                }).finally(() => {
                    loading.value = false;
                });
        });
    });
}

</script>

<template>
    <div class="max-w-4xl mx-auto">
        <div class="py-2">
            <div class="w-full text-lg text-medium tracking-relaxed text-black sm:text-lg text-center" v-if="message !== ''">
                <div class="container">
                    <div class="my-4 flex flex-row text-secondary-900 p-4 bg-primary-100 border border-primary-400 rounded-xl w-full font-base">
                        <div class="w-8 h-8 flex items-center justify-center mr-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 43 43" fill="none"><circle cx="21.5" cy="21.5" r="20.5" stroke="#36255E" stroke-width="2"></circle><path d="M30.8332 17.25L19.3748 28.7083L14.1665 23.5" stroke="#CB056C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                        </div>
                        <div class="flex self-center">
                            {{ message }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!submitted" class="flex flex-col gap-2 mt-3">
                <div class="w-full flex flex-col lg:flex-row gap-2 lg:gap-6">
                    <div class="w-full lg:w-5/12">
                        <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900">{{ label('site.account.first_name') }} <span class="text-red-500">*</span></label>
                        <input type="text" id="first_name" v-model="form.first_name" class="bg-white border border-secondary-100 focus:shadow-md focus:shadow-gray-200 text-gray-900 text-sm rounded focus:ring-secondary-200 focus:border-secondary-100 block w-full p-3" placeholder="" required />
                        <Error v-if="errors.hasOwnProperty('first_name')" :error="errors?.first_name[0]"></Error>
                    </div>
                    <div class="w-full lg:w-2/12">
                        <label for="insertion" class="block mb-2 text-sm font-medium text-gray-900">{{ label('site.account.insertion') }}</label>
                        <input type="text" id="insertion" v-model="form.insertion" class="bg-white border border-secondary-100 focus:shadow-md focus:shadow-gray-200 text-gray-900 text-sm rounded focus:ring-secondary-200 focus:border-secondary-100 block w-full p-3" placeholder="" />
                        <Error v-if="errors.hasOwnProperty('suffix')" :error="errors?.insertion[0]"></Error>
                    </div>
                    <div class="w-full lg:w-5/12">
                        <label for="last_name" class="block mb-2 text-sm font-medium text-gray-900">{{ label('site.account.last_name') }} <span class="text-red-500">*</span></label>
                        <input type="text" id="last_name" v-model="form.last_name" class="bg-white border border-secondary-100 focus:shadow-md focus:shadow-gray-200 text-gray-900 text-sm rounded focus:ring-secondary-200 focus:border-secondary-100 block w-full p-3" placeholder="" required />
                        <Error v-if="errors.hasOwnProperty('last_name')" :error="errors?.last_name[0]"></Error>
                    </div>
                </div>
                <div>
                    <label for="email" class="block mb-2 text-sm font-medium text-gray-900">{{ label('site.account.email') }} <span class="text-red-500">*</span></label>
                    <input type="email" id="email" v-model="form.email" class="bg-white border border-secondary-100 focus:shadow-md focus:shadow-gray-200 text-gray-900 text-sm rounded focus:ring-secondary-200 focus:border-secondary-100 block w-full p-3" placeholder="" required />
                    <Error v-if="errors.hasOwnProperty('email')" :error="errors?.email[0]"></Error>
                </div>
                <div>
                    <label for="phone" class="block mb-2 text-sm font-medium text-gray-900">{{ label('site.account.phone') }}</label>
                    <input type="text" id="email" v-model="form.phone" class="bg-white border border-secondary-100 focus:shadow-md focus:shadow-gray-200 text-gray-900 text-sm rounded focus:ring-secondary-200 focus:border-secondary-100 block w-full p-3" placeholder="" />
                    <Error v-if="errors.hasOwnProperty('phone')" :error="errors?.phone[0]"></Error>
                </div>
                <div>
                    <label for="message" class="block mb-2 text-sm font-medium text-gray-900">{{ label('site.form.message') }}  <span class="text-red-500">*</span></label>
                    <textarea id="message" v-model="form.message" class="bg-white border border-secondary-100 focus:shadow-md focus:shadow-gray-200 text-gray-900 text-sm rounded focus:ring-secondary-200 focus:border-secondary-100 block w-full p-3" placeholder="" required />
                    <Error v-if="errors.hasOwnProperty('message')" :error="errors?.message[0]"></Error>
                </div>
                <button :disabled="loading" class="ml-auto mt-4 sm:flex flex-col place-items-end inline-block cursor-pointer rounded-full bg-primary-500 text-white hover:bg-primary-700 py-3 px-8 text-md font-bold text-muted-foreground" @click="handleForm">
                    <Loader2Icon v-if="loading" class="w-5 h-5 animate-spin"></Loader2Icon>
                    <span v-else v-text="label('site.form.submit')"></span>
                </button>
            </div>
        </div>
    </div>
</template>
