<script setup lang="ts">

import {computed, ref, Ref, watch} from "vue";
import {label} from "../../mixins/translations";
import {AddressResponse} from "../../../responses/AddressResponse";
import {useAddressStore} from "../../stores/address";
import {DialogTrigger} from "radix-vue";
import { XIcon } from "lucide-vue-next";

interface Props {
    current?: AddressResponse,
    countries?: { [key: string]: string },
    showEdit?: boolean,
    showDelete?: boolean

}

const props = withDefaults(defineProps<Props>(), {
    current: {},
    countries: [],
    showEdit: true,
    showDelete: false
})

const addressStore = useAddressStore()
const address = ref(props.current);
const errors = ref({});


const emit = defineEmits<{
    (e: 'delete', value: number): void
    (e: 'select', value: AddressResponse): void
}>()

const updateAddress = async (newValue) => {
    address.value = newValue;
}
const saveAddress = async (address) => {
    let response = await addressStore.updateAddress(address.id, address);
    if(response.message === 'success') {
        open.value = false;
    } else {
        errors.value = response.errors;
    }
}

const deleteAddress = async (address) => {
    let response = await addressStore.deleteAddress(address.id);
    if(response.message === 'success') {
        openDelete.value = false;
        emit('delete', address.id);
    } else {
        console.error('Something went wrong deleting the address.')
    }
}

const open: Ref<Boolean> = ref(false);
const openDelete: Ref<Boolean> = ref(false);

watch(() => props.current, (newValue, oldValue) => {
   address.value = newValue;
})

const salutation = computed(()=> {
    return address.value.salutation !== 'other' ? label('site.account.salutation.'+address.value.salutation) : '';
})

const select = () => {
    emit('select', address.value);
}

</script>

<template>
    <div class="border font-semibold rounded-md p-4 min-h-fit" @click.prevent="select">
        <Modal v-if="showDelete" v-model:open="openDelete">
            <template #trigger-wrapper>
                <div class="relative">
                    <DialogTrigger @click.stop class="absolute right-0 top-0 h-8 w-8 items-center justify-center flex rounded-full cursor-pointer text-end text-sm text-gray-600 hover:bg-secondary-100">
                        <x-icon></x-icon>
                    </DialogTrigger>
                </div>
            </template>
            <template #title>{{ label('site.address.delete.title')}}</template>
            <div class="flex gap-2">
            <button class="bg-primary hover:bg-primary-600 text-white font-bold px-5 pointer rounded-full flex items-center justify-center h-12 " @click.prevent="deleteAddress(address)">{{ label('site.button.delete')}}</button>
            <button class="h-12 px-5 pointer rounded-full flex items-center justify-center font-bold text-white bg-secondary-950 hover:bg-secondary-900 transition-all duration-500" @click.prevent="openDelete = false">{{ label('site.button.cancel')}}</button>
            </div>
        </Modal>
        <div class="w-full" v-show="address.type === 'invoice'">{{ address.company }}</div>
        <div class="w-full">
            <span v-html="salutation"></span>
            {{ address.first_name }}
            {{ address.insertion }} {{ address.last_name }}
        </div>
        <div class="w-full">{{ address.address_line_1 }}</div>
        <div class="w-full">{{ address.address_line_2 }}</div>
        <div class="w-full">{{ address.street }} {{ address.house_number }}
            {{ address.house_number_addition }}
        </div>
        <div class="w-full">{{ address.postal }} {{ address.city }}</div>
        <div class="w-full">{{ countries[address.country] ?? '' }}</div>
        <Modal v-if="showEdit" v-model:open="open" width-class="max-w-[700px]">
            <template #trigger-wrapper class="text-right">
                <div class="relative">
                <DialogTrigger @click.stop class="absolute right-0 bottom-0 cursor-pointer text-end text-sm text-gray-600">
                    {{ label('site.address.edit') }}
                </DialogTrigger>
                </div>
            </template>
            <template #title>{{ label('site.address.edit')}}</template>
            <address-input :show-company="address.type === 'invoice'" class="min-h-12" :errors="errors" :model-value="address" @update:model-value="value => updateAddress(value)" :countries="countries" :show-inputs="true" :show-address-inputs="true" :hide-preview="true">
                <template #buttons>
                    <button class="bg-primary hover:bg-primary-600 font-bold text-primary-foreground px-5 pointer rounded-full flex items-center justify-center h-12 w-36" @click.prevent="saveAddress(address)">
                        {{ label('site.button.edit') }}
                    </button>
                </template>
            </address-input>
        </Modal>
    </div>
</template>
