<script setup lang="ts">
import {useFilterStore} from "../../stores/filter.ts";
import {computed, ref} from "vue";
import {label} from "../../mixins/translations";
import {FilterResponse} from "../../../responses/FilterResponse";
interface Props{
    filter: FilterResponse,
    initial: Object,
    filterType: string
}
const props = defineProps<Props>();
const filterStore = useFilterStore();
const filterOptions = computed(() => {
    if(props.filter.property){
        return props.filter.property_options;
    }
    else{
        return props.filter.options;
    }
});

const emit = defineEmits<{
    (e: 'updateFilter', {filterOptionSlug: String, filterSlug: String}): void;
}>();

const updateFilter = (filterOptionSlug, filterSlug) => {
    filterStore.addOrRemoveFilter(filterSlug, filterOptionSlug);
}
const isChecked = (filterOptionSlug, filterSlug) => {
    return filterStore.hasFilterWithValue(filterSlug, filterOptionSlug);
}
</script>

<template>
    <div class="w-full mt-2 mb-5">
        <h3 class="mt-4 text-lg font-bold tracking-tight text-gray-900 lg:text-lg pb-3">{{ filter.name }}</h3>
        <div class="w-full">
            <nav class="flex flex-col text-base font-normal text-gray-700">
                <div v-for="(filterOption, index) in filterOptions" role="button"
                     class="flex items-center w-full p-0 leading-tight transition-all rounded-sm outline-none text-start hover:bg-secondary-50
                     hover:bg-opacity-80 hover:text-gray-900 focus:bg-secondary-50 focus:bg-opacity-80 focus:text-gray-900 active:bg-secondary-50
                     active:bg-opacity-80 active:text-gray-900">
                    <label class="flex items-center w-full py-1 cursor-pointer">
                        <div class="grid mr-3 place-items-center">
                            <div class="inline-flex items-center">
                                <label class="relative flex items-center p-0 rounded-sm cursor-pointer">
                                    <input name="vertical-list" id="vertical-list-vue" type="checkbox" class="bg-white before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-sm border border-blue-gray-200 text-gray-900 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:before:bg-gray-900 hover:before:opacity-0"
                                        :value="filterOption.slug"
                                        :checked="isChecked(filterOption.slug, filter.slug)"
                                        @change="updateFilter(filterOption.slug, filter.slug)" />
                                    <span class="absolute text-gray-900 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                        <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" stroke="transparent" stroke-width="0" stroke-linecap="round" stroke-linejoin="round">
                                            <rect width="18" height="18" x="3" y="3" rx="2"/>
                                        </svg>
                                    </span>
                                </label>
                            </div>
                        </div>
                        <p class="block font-sans text-sm antialiased font-medium leading-relaxed text-gray-700">
                            {{ filterOption.name }} <span class="rounded-full bg-secondary-100 px-2 py-0.5 text-xs font-semibold text-black ml-3"> {{ filterOption.product_count }}</span>
                        </p>
                    </label>
                </div>
            </nav>
        </div>
    </div>
</template>
