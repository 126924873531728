<script setup lang="ts">
import {computed, ComputedRef, ModelRef} from "vue";
import {type PaginationLink} from "../../types/Pagination";
import PaginationWindow from "../../utils/PaginationWindow";

const model = defineModel()

const emit = defineEmits<{
    (e: 'loadNextPage', page: Number): void;
}>();

const goToPage = (link: PaginationLink): void => {
    if (link.url == null) return; // Skip when url is not set

    if (link.active) return; // Skip already active page

    emit('loadNextPage', parseInt(link.url.replace('?page=', '')));
}

const links: ComputedRef<PaginationLink[]> = computed(() => {
    return (new PaginationWindow(model.value?.meta)).process()
})
</script>

<template>
    <nav aria-label="Page navigation example" v-if="model && model.meta.last_page > 1" class="flex">
        <div class="float-none m-auto table">
            <ul class="flex items-center h-10 text-sm bg-white rounded-full overflow-hidden border border-gray-300 divide-x divide-gray-300">
                <li v-for="(link, key) of links" :key="key" class="hidden first:inline-block last:inline-block sm:inline-block">
                    <button
                        type="button"
                        :class="{
                            'font-bold': link.active,
                            'disabled cursor-not-allowed opacity-25': link.url === null && link.label !== '...',
                        }"
                        :disabled="link.url === null || link.active"
                        class="flex items-center justify-center lg:pr-3 px-3 h-10 leading-tight enabled:hover:bg-gray-100"
                        v-html="link.label"
                        @click.prevent="goToPage(link)"
                    ></button>
                </li>
            </ul>
        </div>
    </nav>
</template>
